
/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataSummary from '../../widget/DataSummary';
import { FormatedObject, FormatedText } from '../../widget/SummaryHelpers';
import { dateToStr } from '../../../resources/Util';


const mapStateToProps = state => {
    return {
        project: state.project
    };
};


/*
 * Show description properties for a project.
 */
class ProjectDescription extends Component {
    render() {
        const {
            attachments,
            description,
            endDate,
            funding,
            startDate,
            title
        } = this.props.project.description;
        // Create list of (label, value)-pairs for displaying the entered
        // information.
        const elements = [];
        elements.push({
            label: 'Title',
            value: <FormatedText text={title} message='Title is missing' />
        });
        elements.push({
            label: 'Description',
            value: <FormatedText text={description} message='Description is missing' />
        });
        elements.push({
            label: 'Expected start date',
            value: dateToStr(startDate)
        });
        elements.push({
            label: 'Expected end date',
            value: dateToStr(endDate)}
        );
        elements.push({
            label: 'Funding',
            value: <FormatedObject object={funding} message='Funding not selected' />
        });
        let attachmentList = null;
        if (attachments.length > 0) {
            attachmentList = attachments.map((obj, i) => {
                const { description, name } = obj;
                let title;
                if ((description == null) || (description === '')) {
                    title = name;
                } else {
                    title = description + ' (' + name + ')';
                }
                return (<div key={i}>{ title }</div>);
            });
            elements.push({label: 'Attachments', value: attachmentList});
        }
        return (<DataSummary className='last-prop-table' elements={elements} />);
    }
}

export default connect(mapStateToProps)(ProjectDescription);
