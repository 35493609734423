/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Divider, Form, Icon, Input, TextArea } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import ObjectListing from '../../widget/ObjectListing.js';
import SearchableDropdown from '../../widget/SearchableDropdown';
import ShareLinkModal from '../subform/ShareLinkModal.js'
import Subheader from '../../widget/Subheader';
import UploadFileModal from '../subform/UploadFileModal';
import { SHARE_LINK, UPLOAD_FILE } from '../../../resources/Attachments'
import { UNSELECTED_FUNDING } from '../../../resources/Project'
import { Today } from '../../../resources/Util'
import { updateProjectDescription } from '../../../actions/Project';
import 'react-datepicker/dist/react-datepicker.css';
import '../../app.css';
import '../project.css';


const mapStateToProps = state => {
  return {
      app: state.app,
      project: state.project
  };
};


function mapDispatchToProps(dispatch) {
  return {
      updateState: (data) => dispatch(updateProjectDescription(data))
  };
}


/*
 * Show icon for attachment that depicts the attachment type.
 */
const attachmentIcon = (attachment) => {
    if (attachment.type === SHARE_LINK) {
        return 'linkify';
    } else {
        return 'file outline';
    }
}


/*
 * The display label for attachments depends on whether a description was
 * given or not and on the attachment type (if no description was given).
 */
const attachmentLabel = (attachment) => {
    const { description, name, uri } = attachment;
    if ((description == null) || (description === '')) {
        if ((name == null) && (uri != null)) {
            return uri;
        } else {
            return name;
        }
    } else {
        return description;
    }
}


class ProjectDescriptionForm extends Component {
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintain project
        // information. Add additional elements here if required by the form.
        this.state = {
            attachments: [],
            title: "",
            description: "",
            startDate: Today(),
            endDate: Today(),
            funding: UNSELECTED_FUNDING(),
            modal: null
        };
    }
    /*
     * Initialize the local form state from the global project state when the
     * component mounts.
     */
    componentDidMount() {
        const project = this.props.project.description;
        if (project != null) {
            this.setState({
                attachments: project.attachments,
                title: project.title,
                description: project.description,
                startDate: project.startDate,
                endDate: project.endDate,
                funding: project.funding,
                modal: null
            })
        }
    }
    /*
     * Update the values that maintain the form state in the global application
     * state.
     */
    componentWillUnmount() {
        // Construct an object (from the component state) that has the
        // following format:
        //
        // - title: "string"
        // - description: "string"
        // - funding: {"id": "int", "name": "string"}
        // - start_date: "string"
        // - end_date: "string"
        // - attachments: []
        //  }
        //
        const {
            attachments,
            description,
            endDate,
            funding,
            startDate,
            title
        } = this.state;
        const project = {
            title,
            description,
            funding,
            startDate,
            endDate,
            attachments
        }
        this.props.updateState(project)
    }
    /*
     * Add shared link to the list of attachments.
     */
    addSharedLink = (uri, description) => {
        const attachment = {type: SHARE_LINK, name: uri, uri, description};
        this.setState({
            attachments: this.state.attachments.concat([attachment]),
            modal: null,
        })
    }
    /*
     * Add file for upload to the list of attachments.
     */
    addUploadFile = (file, description) => {
        const attachment = {type: UPLOAD_FILE, name: file.name, file, description};
        this.setState({
            attachments: this.state.attachments.concat([attachment]),
            modal: null,
        })
    }
    /*
     * Delete the given attachment from the list of attachments for the
     * research project description.
     */
    deleteAttachment = (index) => {
        const attachments = [];
        this.state.attachments.forEach((element, i) => {
            if (i !== index) {
                attachments.push(element);
            }
        });
        this.setState({
           attachments: attachments
       });
    }
    /*
     * Event handler for changes in the project funding statusdropdown.
     */
    handleFundingUpdate = (funding) => {
        this.setStateProperty('funding', funding);
    }
    /*
     * Set the modal variable in the internal state to null to hide all modal
     * forms.
     */
    hideAllModals = () => {
        this.setState({modal: null});
    }
    /*
     * Render input for that includes text fileds for the project title and
     * descriptions as well as a selector for the project funding status. The
     * controlled vocabulary for funding status is included in the masterdata
     * that is part of the application state (this.props.app.masterdata).
     */
    render() {
        const {
            attachments,
            title,
            description,
            startDate,
            endDate,
            funding,
            modal
        } = this.state;
        const fundingStaus = this.props.app.masterdata.projectFundingStatus;
        // Show a modal form if the modal state is set.
        let modalForm = null;
        if (modal === SHARE_LINK) {
            modalForm = <ShareLinkModal
                onCancel={this.hideAllModals}
                onSubmit={this.addSharedLink}
            />
        } else if (modal === UPLOAD_FILE) {
            modalForm = <UploadFileModal
                onCancel={this.hideAllModals}
                onSubmit={this.addUploadFile}
            />
        }
        return (
                <Form size='large'>
                    <Form.Field
                        control={Input}
                        label="Title"
                        onChange={(e) => this.setStateProperty('title', e.target.value)}
                        value={title} />
                    <Form.Group className='long content'>
                        <Form.Field>
                            <label>Description</label>
                            <TextArea
                                id='description'
                                onChange={(e) => this.setStateProperty('description', e.target.value)}
                                value={description}/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>Expected start date</label>
                            <DatePicker
                                showPopperArrow={false}
                                popperPlacement="bottom"
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"]
                                  }
                                }}
                                onChange={(e) => this.setStateProperty('startDate', e)}
                                dateFormat="yyyy-MM-dd" selected={startDate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Expected end date</label>
                            <DatePicker
                                showPopperArrow={false}
                                popperPlacement="bottom"
                                popperModifiers={{
                                  flip: {
                                    behavior: ["bottom"]
                                  }
                                }}
                                onChange={(e) => this.setStateProperty('endDate', e)}
                                dateFormat="yyyy-MM-dd" selected={endDate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Funding</label>
                            <SearchableDropdown
                                enableSearch={false}
                                elements={fundingStaus}
                                selectedElement={funding}
                                onUpdate={this.handleFundingUpdate}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider />
                    <Subheader title='Additional Resources' />
                    <Form.Field>
                        <ObjectListing
                            elements={attachments}
                            getIcon={attachmentIcon}
                            getName={attachmentLabel}
                            onDelete={this.deleteAttachment}
                        />
                        <div className='form-action-buttons'>
                            <Button
                                icon
                                primary
                                labelPosition='left'
                                size='small'
                                onClick={this.showUploadFileModal}
                            >
                                <Icon name='upload' />
                                Upload File
                            </Button>
                            <Button
                                icon
                                primary
                                labelPosition='left'
                                size='small'
                                onClick={this.showShareLinkModal}
                            >
                                <Icon name='linkify' />
                                Link Document
                            </Button>
                        </div>
                    </Form.Field>
                    { modalForm }
                </Form>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        console.log(name + ' ' + value)
        this.setState({
            [name]: value
        })
    }
    /*
     * Update internal state to show the share link modal form.
     */
    showShareLinkModal = () => {
        this.setState({modal: SHARE_LINK});
    }
    /*
     * Update internal state to show the file upload modal form.
     */
    showUploadFileModal = () => {
        this.setState({modal: UPLOAD_FILE});
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDescriptionForm);
