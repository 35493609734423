/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import '../app.css';


/*
 * Context menu for subforms. Currently only shows a delete option.
 */
class ContextMenu extends Component {
    static propTypes = {
        onDelete: PropTypes.func.isRequired,
        text: PropTypes.string.isRequired
    }
    render() {
        const { onDelete, text } = this.props;
        return (
            <div className='subform-context-menu'>
                <Dropdown icon='ellipsis vertical'>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            key='delete'
                            text={text}
                            icon='trash'
                            onClick={onDelete}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

export default ContextMenu;
