/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, List } from 'semantic-ui-react';
import './widget.css'


/*
 * Listing of objects as labels with an optional delete button.
 */
class ObjectListing extends Component {
    static propTypes = {
        elements: PropTypes.array.isRequired,
        getIcon: PropTypes.func,
        getName: PropTypes.func.isRequired,
        onDelete: PropTypes.func
    }
    render() {
        const { elements, getIcon, getName, onDelete } = this.props;
        // Return null if the element list is empty.
        if (elements.length === 0) {
            return null;
        }
        return (
            <List verticalAlign='middle'>
                { elements.map((el, i) => {
                    let typeIcon = null;
                    if (getIcon != null) {
                        typeIcon = (<Icon name={getIcon(el)} size='large'/>);
                    }
                    let deleteButton = null;
                    if (onDelete != null) {
                        deleteButton = (
                            <Button
                                icon='trash'
                                onClick={() => onDelete(i)}
                            />
                        );
                    }
                    return (
                        <List.Item key={i}>
                            <List.Content floated='right'>
                                { deleteButton }
                            </List.Content>
                            <List.Content className='list-item' floated='left'>
                                { typeIcon }
                                { getName(el) }
                            </List.Content>
                        </List.Item>
                );})}
            </List>
        );
    }
}

export default ObjectListing;
