
/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './widget.css'


/*
 * Tabular listing of object properties for a summary overview of entered
 * information.
 */
class DataSummary extends Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
        elements: PropTypes.array.isRequired
    }
    render() {
        const { className, elements } = this.props;
        return (
            <table className={className}><tbody>
            {elements.map((row, i) => {
                const { label, value } = row;
                return (
                    <tr key={i}>
                        <td className='prop-label'>{ label }</td>
                        <td className='prop-text'>{ value }</td>
                    </tr>
                );
            })}
            </tbody></table>
        );
    }
}

export default DataSummary;
