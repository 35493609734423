/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Loader, Icon, Message, Menu } from 'semantic-ui-react'
import MainPage from './MainPage';
import ProjectWorkflow from './project/ProjectWorkflow';
import WorkerWorkflow from './worker/WorkerWorkflow';
import { fetchApi } from "../actions/App";
import banner from '../../assets/logo.png';
import './app.css';


function mapStateToProps(state) {
  return {
      app: state.app
  };
};


function mapDispatchToProps(dispatch) {
  return {
      fetchApi: () => dispatch(fetchApi())
  };
}


class App extends Component {
    componentDidMount() {
        this.props.fetchApi();
    }
    render() {
        const { fetchError, isFetching } = this.props.app;
        // The main content for the application depends on whether the API
        // service descriptor has been fetched successfully or not.
        let content = null;
        if (fetchError != null) {
            // There was an error while fetching the service descriptor. This
            // most-likely indicates that the API is not available.
            content = (
                <div className='app-notification'>
                    <Message icon negative>
                        <Icon name='warning' />
                        <Message.Content>
                            <Message.Header>
                                There was an error fetching the API Service Descriptor
                            </Message.Header>
                            { fetchError }
                        </Message.Content>
                    </Message>
                </div>
            );
        } else if (isFetching === true) {
            // Show a spinner to indicate that the API service descriptor
            // is currently being fetched (and that the application is not
            // ready).
            content = (
                <div className='app-notification'>
                    <Loader inline='centered' active>
                        Loading API Service Descriptor ...
                    </Loader>
                </div>
            );
        } else {
            content = (
                <Router>
                    <Switch>
                        <Route exact path='/' component={MainPage} />
                        <Route exact path='/projects' component={ProjectWorkflow} />
                        <Route path='/projects/:project_id' component={ProjectWorkflow} />
                        <Route exact path='/workers' component={WorkerWorkflow} />
                        <Route path='/workers/:worker_id' component={WorkerWorkflow} />
                    </Switch>
                </Router>
            );
        }
        return (
            <div className="app">
              <Menu className="navigation">
                <img src={banner} alt="NYU DS3" />
              </Menu>
              { content }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
