/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Icon, List } from 'semantic-ui-react';
import ListItem from '../../widget/ListItem';
import ScheduleForm from '../subform/ScheduleForm';
import { updateWorkerAvailability } from "../../../actions/Worker";
import { del, noneIfEmpty, replace, timePeriod, Today } from '../../../resources/Util'
import '../../app.css';
import '../worker.css';


const mapStateToProps = state => {
    return {
        app: state.app,
        worker: state.worker
    };
};

function mapDispatchToProps(dispatch) {
    return {
        updateState: (data) => dispatch(updateWorkerAvailability(data))
    };
}


class AvailabilityForm extends Component {
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintains the list of
        // worker's schedule information. Add additional elements here if
        // required by the form.
        this.state = {
            availabilities: [],
            endDate: Today(),
            hours: '',
            selectedAvailability: -1,
            startDate: Today()
        };
    }
    /*
     * Initialize the local form state from the global worker state when the
     * component mounts.
     */
    componentDidMount() {
        const availabilities = this.props.worker.availability;
        if (availabilities != null) {
            if (availabilities.length > 0) {
                const selectedItem = availabilities.length - 1;
                const lastSchedule = availabilities[selectedItem];
                this.setState({
                    availabilities: availabilities,
                    endDate: lastSchedule.endDate,
                    hours: lastSchedule.hours,
                    selectedAvailability: selectedItem,
                    startDate: lastSchedule.startDate,
                })
            }
        }
    }
    /*
     * Update the form state in the global application state.
     */
    componentWillUnmount() {
        // Construct an object (from the component state) that is a list of
        // schedule objects with the following format:
        //
        // - startDate: "string"
        // - endDate: "string"
        // - hours: "string"
        //
        this.props.updateState(this.updateAvailabilityList());
    }
    /*
     * Add a new availability element to the list.
     */
    addAvailability = () => {
        this.setState({
            availabilities: this.updateAvailabilityList(),
            endDate: Today(),
            hours: '',
            selectedAvailability: -1,
            startDate: Today()
        });
    }
    /*
     * Delete the availability item that is currently being edited.
     */
    deleteAvailability = () => {
        const { availabilities, selectedAvailability } = this.state;
        const updSchedule = del(availabilities, selectedAvailability);
        let schedule = null;
        let newSelection = -1;
        if (updSchedule.length > 0) {
            newSelection = selectedAvailability;
            if ((selectedAvailability === -1) || (newSelection >= updSchedule.length)) {
                newSelection = updSchedule.length - 1;
            } else if (newSelection < 0) {
                newSelection = 0;
            }
            schedule = updSchedule[newSelection];
        } else {
            schedule = {
                endDate: Today(),
                hours: '',
                startDate: Today()
            };
        }
        this.setState({
            availabilities: updSchedule,
            endDate: schedule.endDate,
            hours: schedule.hours,
            selectedAvailability: newSelection,
            startDate: schedule.startDate
        });
    }
    /*
     * Edit the availability element at the given index position in the array.
     */
    editAvailability = (index) => {
        const schedule = this.state.availabilities[index];
        this.setState({
            availabilities: this.updateAvailabilityList(),
            endDate: schedule.endDate,
            hours: schedule.hours,
            selectedAvailability: index,
            startDate: schedule.startDate
        });
    }
    /*
     * Render the input form for worker schedule information. For each
     * schedule the time period is maintained.
     */
    render() {
        const {
            availabilities,
            endDate,
            hours,
            selectedAvailability,
            startDate
        } = this.state;
        // Show the delete availability option in the header menu if there is
        // more than one availability in the list.
        let callbackDelete = null;
        if (!(
            ((selectedAvailability === 0) && (availabilities.length === 1)) ||
            (availabilities.length === 0)
        )) {
            callbackDelete = this.deleteAvailability;
        }
        let newAvailabilityForm = null;
        if (selectedAvailability === -1) {
            newAvailabilityForm = (
                <List.Item key={-1}>
                    <ScheduleForm
                        endDate={endDate}
                        hours={hours}
                        onDelete={callbackDelete}
                        onUpdate={this.setStateProperty}
                        startDate={startDate}
                    />
                </List.Item>
            );
        }
        return (
            <Container fluid>
                <List divided relaxed size='big'>
                    {availabilities.map((obj, i) => {
                        if (i === selectedAvailability) {
                            return (
                                <List.Item key={i}>
                                    <ScheduleForm
                                        endDate={endDate}
                                        hours={hours}
                                        onDelete={callbackDelete}
                                        onUpdate={this.setStateProperty}
                                        startDate={startDate}
                                    />
                                </List.Item>
                            );
                        } else {
                            return (
                                <ListItem
                                    key={i}
                                    index={i}
                                    icon='calendar alternate outline'
                                    header={timePeriod(obj.startDate, obj.endDate)}
                                    name={noneIfEmpty(obj.hours)}
                                    onClick={this.editAvailability}
                                />
                            );
                        }
                    })}
                    { newAvailabilityForm }
                </List>
                <div className='form-action-buttons'>
                    <Button
                        icon
                        labelPosition='left'
                        primary
                        size='small'
                        onClick={this.addAvailability}
                    >
                        <Icon name='calendar alternate outline' />
                        Add Availability
                    </Button>
                </div>
            </Container>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    /*
     * Update the current entry using the values in the input form.
     */
    updateAvailabilityList = () => {
        const {
            availabilities,
            endDate,
            hours,
            selectedAvailability,
            startDate
        } = this.state;
        // Create a new availability item (schedule).
        const schedule = { endDate, hours, startDate };
        // Depending on the value of the currently selected availability item
        // the new schedule item is either appended to the list or the edited
        // item in the list is replaced.
        if (selectedAvailability === -1) {
            return [...availabilities, schedule];
        } else {
            return replace(availabilities, selectedAvailability, schedule);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityForm);
