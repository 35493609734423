/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/*
 * Return a formated error message for missing or empty text strings.
 */
 import React, { Component } from 'react';
 import PropTypes from 'prop-types';
 import './widget.css'


export function tableCss(index, elements) {
    return index < elements.length -1 ? 'prop-table' : 'last-prop-table';
}


/*
 * Display a formated error message for missing text values.
 */
export class FormatedObject extends Component {
    static propTypes = {
        message: PropTypes.string.isRequired,
        object: PropTypes.object
    }
    render() {
        const { object, message } = this.props;
        if ((object == null) || (object.id === -1)) {
            return (<span className='text-warning'>{message}</span>);
        } else {
            return object.name;
        }
    }
}


/*
 * Display a formated error message for missing text values.
 */
export class FormatedText extends Component {
    static propTypes = {
        message: PropTypes.string.isRequired,
        text: PropTypes.string
    }
    render() {
        const { text, message } = this.props;
        if ((text == null) || (text.trim() === '')) {
            return (<span className='text-warning'>{message}</span>);
        } else {
            return text;
        }
    }
}


export class SkillListing extends Component {
    static propTypes = {
        skills: PropTypes.array
    }
    render() {
        const skills = this.props.skills;
        if (skills.length > 0) {
            return skills.map((obj, i) => {
                const { skill, skillLevel, mandatory } = obj;
                let mandatorySkill = null;
                if (mandatory) {
                    mandatorySkill = ' [mandatory]';
                }
                return (
                    <div key={i}>
                        <FormatedObject
                            object={skill}
                            message='Skill not selected'
                        />
                        {' ('}
                        <FormatedObject
                            object={skillLevel}
                            message='Proficiency not selected'
                        />
                        {')'}
                        { mandatorySkill }
                    </div>
                );
            });
        } else {
            return (<FormatedText message='No skills selected' />);
        }
    }
}
