/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Container, Grid, Header, Icon, Loader, Message } from 'semantic-ui-react'
import AvailabilityForm from './form/AvailabilityForm';
import ContactInformationForm from './form/ContactInformationForm';
import Navigation from '../Navigation';
import QualificationForm from './form/QualificationForm';
import SubmitForm from './form/SubmitForm';
import SuccessForm from './form/SuccessForm';
import WorkerSteps from './WorkerSteps';
import { fetchWorker, showForm, submitWorker } from '../../actions/Worker';
import { isEmpty } from '../../resources/Util';
import {
    FORM_AVAILABILITY, FORM_CONTACT, FORM_COUNT, FORM_QUALIFICATION,
    FORM_SUBMIT, FORM_SUCCESS
} from '../../resources/Worker';
import '../app.css';


function mapStateToProps(state) {
  return {
      app: state.app,
      worker: state.worker
  };
};


function mapDispatchToProps(dispatch) {
  return {
      fetchWorker: (url, masterdata) => dispatch(fetchWorker(url, masterdata)),
      showForm: (formId) => dispatch(showForm(formId)),
      submitWorker: (worker, urls) => dispatch(submitWorker(worker, urls))
  };
}


class WorkerWorkflow extends Component {
    componentDidMount() {
        // Fetch worker handle if the route contains a worker id
        const workerId = this.props.match.params.worker_id;
        if (workerId != null) {
            const { masterdata, urls } = this.props.app;
            this.props.fetchWorker(urls.workersFetch(workerId), masterdata);
        }
    }
    handleSubmit = () => {
        const { app, worker, submitWorker } = this.props;
        submitWorker(worker, app.urls);
    }
    render() {
        const {
            isFetching,
            isSubmitting,
            fetchError,
            formId
        } = this.props.worker;
        let content = null;
        if (fetchError != null) {
            // There was an error while fetching the worker handle.
            content = (
                <div className='app-notification'>
                    <Message icon negative>
                        <Icon name='warning' />
                        <Message.Content>
                            <Message.Header>
                                There was an error fetching the worker information
                            </Message.Header>
                            { fetchError }
                        </Message.Content>
                    </Message>
                </div>
            );
        } else if (isFetching === true) {
            // Show a spinner to indicate that the worker handle is currently
            // being fetched.
            content = (
                <div className='app-notification'>
                    <Loader inline='centered' active>
                        Loading Worker ...
                    </Loader>
                </div>
            );
        } else {
            // Show input form for the current workflow step.
            let form = null;
            let header = null;
            let icon = null;
            if (formId === FORM_CONTACT) {
                form = <ContactInformationForm />;
                header = 'Personal Information';
                icon = 'user circle';
            } else if (formId === FORM_QUALIFICATION) {
                form = <QualificationForm  />;
                header = 'Qualification & Experience';
                icon = 'graduation cap';
            } else if (formId === FORM_AVAILABILITY) {
                form = <AvailabilityForm />;
                header = 'Availability';
                icon = 'calendar alternate outline';
            } else if (formId === FORM_SUBMIT) {
                form = <SubmitForm />;
                header = 'Summary';
                icon = 'send';
            } else if (formId === FORM_SUCCESS) {
                form = <SuccessForm />;
                header = 'Thank you!';
            }
            content = (
                <Grid columns={3} container>
                    <Grid.Row>
                        <Grid.Column width={1} />
                        <Grid.Column width={14}>
                        <Header
                            as='h2'
                            icon
                        >
                            <Icon name={icon} />
                            { header }
                        </Header>
                        { form }
                        </Grid.Column>
                        <Grid.Column width={1} />
                    </Grid.Row>
                </Grid>
            );
        }
        let navigation = null;
        if ((formId !== FORM_SUCCESS) && (fetchError == null) && (isFetching === false)) {
            let isValidInput = false;
            if (formId === FORM_SUBMIT) {
                isValidInput = this.validateInput();
            }
            navigation = (
                <Navigation
                    formId={formId}
                    formCount={FORM_COUNT}
                    isSubmitting={isSubmitting}
                    isValidInput={isValidInput}
                    onNavigate={this.props.showForm}
                    onSubmit={this.handleSubmit}
                />
            );
        }
        return (
            <Container className='app-content'>
                <WorkerSteps formId={formId} />
                <div className='app-form'>
                    { content }
                    { navigation }
                </div>
            </Container>
        );
    }
    /*
     * Validate the input values. Returns false if the current input values are
     * not valid.
     */
    validateInput = () => {
        const { contact, qualification, availability } = this.props.worker;
        // -- Contact Information ---------------------------------------------
        const { name, email } = contact;
        // The worker has to have a name and email.
        if ((isEmpty(name)) || (isEmpty(email))) {
            return false;
        }
        // -- Qualifications --------------------------------------------------
        const { educations, skills } = qualification;
        // For the each education all elements (school, major, country, degree
        // status and type) have to be given.
        if (educations.length === 0) {
            return false;
        }
        for (let i = 0; i < educations.length; i++) {
            const education = educations[i];
            if (isEmpty(education.school)) {
                return false;
            }
            if (isEmpty(education.major)) {
                return false;
            }
            if (education.country.id === -1) {
                return false;
            }
            if (education.degreeStatus.id === -1) {
                return false;
            }
            if (education.degreeType.id === -1) {
                return false;
            }
        }
        // Skills have to have both components selected..
        if (skills.length === 0) {
            return false;
        }
        for (let i = 0; i < skills.length; i++) {
            const skill = skills[i];
            if (skill.skill.id === -1) {
                return false;
            }
            if (skill.skillLevel.id === -1) {
                return false;
            }
        }
        // -- Availabilities --------------------------------------------------
        // For each schedule item startDate, endDate, and hours have to be
        // given.
        if (availability.length === 0) {
            return false;
        }
        for (let i = 0; i < availability.length; i++) {
            const { startDate, endDate, hours } = availability[i];
            if (startDate == null) {
                return false;
            }
            if (endDate == null) {
                return false;
            }
            if (isEmpty(hours)) {
                return false;
            }
        }
        return true;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkerWorkflow));
