/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    CREATE_NEW_PROJECT, PROJECT_FETCH_ERROR, PROJECT_FETCH_START,
    PROJECT_FETCH_SUCCESS, PROJECT_SUBMIT_ERROR, PROJECT_SUBMIT_START,
    PROJECT_SUBMIT_SUCCESS, UPDATE_PROJECT_DESCRIPTION,
    UPDATE_PROJECT_RESEARCHERS, UPDATE_PROJECT_RESOURCES, SHOW_PROJECT_FORM
} from '../actions/Project';
import { FORM_RESEARCHERS, FORM_SUCCESS } from '../resources/Project';


/*
 * The project state maintains information about a new project that is entered
 * by a user via the different entry forms. The project state is divided into
 * three main parts according to the three different input forms:
 *
 * "researchers": [{
 *   "full_name": "string",
 *   "email": "string",
 *   "positionID": [{
 *     "affiliation_id": 0,
 *     "status_id": 0
 *   }],
 *   "positionName": [{
 *     "affiliation_name": "string",
 *     "status_name": "string"
 *   }]
 * }]
 *
 * "description": {
 *   "title": "string",
 *   "description": "string",
 *   "funding_status_id": 0,
 *   "funding_status_name": "string",
 *   "expected_time": {
 *     "start_date": "string",
 *     "end_date": "string"
 *   }
 * }
 *
 * "resources": [{
 *   "expected_time": {
 *     "start_date": "string",
 *     "end_date": "string"
 *   },
 *   "weekly_hours": 0,
 *   "skillInfoID": [{
 *     "skill_id": 0,
 *     "skill_level_id": 0,
 *     "mandatory": 0
 *   }],
 *   "skillInfoName": [{
 *     "skill_name": "string",
 *     "skill_level_name": "string",
 *     "mandatory": "string"
 *   }]
 * }]
 *
 */
const INITIAL_STATE = {
    isFetching: false,
    fetchError: null,
    formId: FORM_RESEARCHERS,
    isSubmitting: false,
    submitError: null,
    projectId: null,
    researchers: [],
    description: null,
    resources: [],
}


/*
 * Reducers for actions that modify information about the proposed project.
 */
const project = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_NEW_PROJECT:
            return INITIAL_STATE;
        case PROJECT_FETCH_ERROR:
            return {...state, isFetching: false, fetchError: action.payload};
        case PROJECT_FETCH_START:
            return {...state, isFetching: true, fetchError: null};
        case PROJECT_FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fetchError: null,
                projectId: action.payload.projectId,
                description: action.payload.description,
                researchers: action.payload.researchers,
                resources: action.payload.resources
            };
        case PROJECT_SUBMIT_ERROR:
            return {...state, isSubmitting: false, submitError: action.payload};
        case PROJECT_SUBMIT_START:
            return {...state, isSubmitting: true, submitError: null};
        case PROJECT_SUBMIT_SUCCESS:
            return {
                ...state,
                formId: FORM_SUCCESS,
                isSubmitting: false,
                submitError: null,
                projectId: action.payload.project_id,
            };
        case UPDATE_PROJECT_DESCRIPTION:
            return {...state, description: action.payload};
        case UPDATE_PROJECT_RESEARCHERS:
            return {...state, researchers: action.payload};
        case UPDATE_PROJECT_RESOURCES:
            return {...state, resources: action.payload};
        case SHOW_PROJECT_FORM:
            return {...state, formId: action.payload};
        default:
            return state;
    }
}


export default project;
