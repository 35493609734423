/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Grid } from 'semantic-ui-react';
import './app.css';


/*
 * Simple navigation bar that displays a 'Back' and 'Next' button. If the last
 * input form is reached (i.e., the project summary page), a 'Submit' button is
 * shown instead of 'Next'.
 */
class Navigation extends Component {
    static propTypes = {
        formCount: PropTypes.number.isRequired,
        formId: PropTypes.number.isRequired,
        isSubmitting: PropTypes.bool.isRequired,
        isValidInput: PropTypes.bool.isRequired,
        onNavigate: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    }
    /*
     * Navigate to the previous input form.
     */
    handleNavigateBack = () => {
        const { formId, onNavigate } = this.props;
        if (formId > 0) {
            onNavigate(formId - 1);
        }
    }
    /*
     * Navigate to the next input form.
     */
    handleNavigateNext = () => {
        const { formCount, formId, onNavigate } = this.props;
        if (formId < formCount) {
            onNavigate(formId + 1);
        }
    }
    render() {
        const {
            formCount,
            formId,
            isSubmitting,
            isValidInput,
            onSubmit
        } = this.props;
        // The appearance of the next button depends on whether we have
        // reached the final stage of the project input workflow or not.
        let nextButton = null;
        if (formId === formCount - 2) {
            if (isSubmitting) {
                nextButton = (<Button positive loading> Loading </Button>);
            } else {
                nextButton = (
                    <Button
                        icon
                        labelPosition='right'
                        positive
                        disabled={!isValidInput}
                        onClick={onSubmit}
                    >
                        <Icon name='checkmark' />
                        Submit
                    </Button>
                );
            }
        } else {
            nextButton = (
                <Button
                    icon
                    labelPosition='right'
                    onClick={this.handleNavigateNext}
                >
                    <Icon name='arrow right' />
                    Next
                </Button>
            );
        }
        return (
            <div className='nav'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='right' width={8}>
                            <Button
                                icon
                                labelPosition='left'
                                disabled={formId < 1 || isSubmitting}
                                onClick={this.handleNavigateBack}
                            >
                                <Icon name='arrow left' />
                                Back
                            </Button>
                        </Grid.Column>
                        <Grid.Column textAlign='left' width={8}>
                            { nextButton }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}


export default Navigation;
