/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { uploadFiles } from './Files';
import { SHARE_LINK, UPLOAD_FILE } from '../resources/Attachments'
import { Project } from '../resources/Project';
import { dateToStr } from '../resources/Util';


// -- Action Identifier -------------------------------------------------------

export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';
export const PROJECT_FETCH_ERROR = 'PROJECT_FETCH_ERROR';
export const PROJECT_FETCH_START = 'PROJECT_FETCH_START';
export const PROJECT_FETCH_SUCCESS = 'PROJECT_FETCH_SUCCESS';
export const PROJECT_SUBMIT_ERROR = 'PROJECT_SUBMIT_ERROR';
export const PROJECT_SUBMIT_START = 'PROJECT_SUBMIT_START';
export const PROJECT_SUBMIT_SUCCESS = 'PROJECT_SUBMIT_SUCCESS';
export const UPDATE_PROJECT_DESCRIPTION = 'UPDATE_PROJECT_DESCRIPTION'
export const UPDATE_PROJECT_RESEARCHERS = 'UPDATE_PROJECT_RESEARCHERS'
export const UPDATE_PROJECT_RESOURCES = 'UPDATE_PROJECT_RESOURCES'
export const SHOW_PROJECT_FORM = 'SHOW_PROJECT_FORM';


// -- Actions that update the global project state ----------------------------

/*
 * Update the project description. Expects a data object that has the following
 * format: {
 *   "title": "string",
 *   "description": "string",
 *   "funding": {"id": 0, "name": "string"},
 *   "startDate": "string",
 *   "endDate": "string",
 *   "attachments": [{
 *     "name": "string",
 *     "uri": "string",
 *     "description": "string",
 *     "file": FileObject
 *   }]
 * }
 */
export const updateProjectDescription = (data) => ({
    type: UPDATE_PROJECT_DESCRIPTION, payload: data
})


/*
 * Update the list of principle investigators for the proposed project. Expects
 * an array of objects that have the following format:
 *
 * {
 *   "name": "string",
 *   "email_id": "string",
 *   "affiliations": [{
 *     "department": {"id": 0, "name": "string"},
 *     "role": {"id": 0, "name": "string"}
 *   }]
 * }
 */
export const updateProjectResearchers = (data) => ({
    type: UPDATE_PROJECT_RESEARCHERS, payload: data
})


/*
 * Update the list of requested project resources. Expects an array of objects
 * that have the following format:
 *
 * {
 *   "skills": [{
 *     "skill: {"id": 0, "name": "string"},
 *     "skillLevel: {"id": 0, "name": "string"},
 *     "mandatory": "bool"
 *   }],
 *   "startDate": "string",
 *   "endDate": "string",
 *   "hours": 0
 * }
 */
export const updateProjectResources = (data) => ({
    type: UPDATE_PROJECT_RESOURCES, payload: data
})


// -- Actions to fetch project data -------------------------------------------

export const fetchProject = (url, masterdata) => {
    return dispatch => {
        dispatch({type: PROJECT_FETCH_START});
        return fetch(url)
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => (
                        dispatch(fetchErrorHandler(json.message))
                    ));
                } else {
                    response.json().then(json => (
                        dispatch(fetchSuccessHandler(Project(json, masterdata)))
                    ));
                }
            })
    }
}

const fetchErrorHandler = (msg) => {
    return {type: PROJECT_FETCH_ERROR, payload: msg}
};


const fetchSuccessHandler = (json) => {
    return {type: PROJECT_FETCH_SUCCESS, payload: json}
};


// -- Actions to submit project information to the API ------------------------

export const submitProject = (project, urls) => {
    // Prepare the list of attachments.
    const attachments = project.description.attachments;
    const files = [];
    const links = [];
    const uploads = [];
    attachments.forEach((obj) => {
        if (obj.type === UPLOAD_FILE) {
            const file = FILE(obj);
            files.push(file);
            if (obj.file_id == null) {
                uploads.push({'obj': file, 'file': obj.file});
            }
        } else if (obj.type === SHARE_LINK) {
            links.push(LINK(obj));
        }
    });
    if (uploads.length > 0) {
        return dispatch => {
            dispatch({type: PROJECT_SUBMIT_START});
            return dispatch(
                uploadFiles(
                    uploads,
                    {project, attachments: {files, links}, urls},
                    submitProjectWithAttachments,
                    submitErrorHandler
                )
            );
        }
    } else {
        return dispatch => {
            dispatch({type: PROJECT_SUBMIT_START});
            return dispatch(
                submitProjectWithAttachments(
                    project,
                    {files, links},
                    urls
                )
            )
        }
    }
}


/*
 * Create POST request that submits project information after all attached
 * files have been uploaded.
 */
export const submitProjectWithAttachments = (project, attachments, urls) => {
    const { researchers, description, resources } = project;
    // Create the request body.
    const data = {
        'title': description.title,
        'description': description.description,
        'funding_status_id': description.funding.id,
        'expected_time': {
            'start_date': dateToStr(description.startDate),
            'end_date': dateToStr(description.endDate)
        },
        'attachments': attachments
    };
    console.log(data);
    let url;
    if (project.projectId != null) {
        data['project_id'] = project.projectId;
        url = urls.projectsUpdate(project.projectId);
    } else {
        url = urls.projectsCreate();
    }
    data['researchers'] = researchers.map((r) => (RESEARCHER(r)));
    data['resources'] = resources.map((r) => (RESOURCE(r)));
    // Submit request.
    const headers = {'Content-Type': 'application/json'};
    const body = JSON.stringify(data);
    return dispatch => (
        fetch(url, {method: 'POST', headers, body})
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => {
                        dispatch(submitErrorHandler(json.message));
                    });
                } else {
                    response.json().then(json => {
                        dispatch(submitSuccessHandler(json));
                    })
                }
            })
            .catch(error => dispatch(submitErrorHandler(error.message)))
    )
}


const submitErrorHandler = (msg) => {
    return {type: PROJECT_SUBMIT_ERROR, payload: msg}
};


const submitSuccessHandler = (json) => {
    return {type: PROJECT_SUBMIT_SUCCESS, payload: json}
};

export const dismissSubmitError = () => (submitErrorHandler());



// -- Actionas to navigate input forms ----------------------------------------

export function showForm(formId) {
    return {type: SHOW_PROJECT_FORM, payload: formId};
}


// -- Object serializers ------------------------------------------------------

/*
 * Serialize attached file object for request body.
 */
const FILE = (obj) => {
    const data = {'name': obj.name}
    if (obj.description != null) {
        data['description'] = obj.description;
    }
    if (obj.file_id != null) {
        data['file_id'] = obj.file_id;
    }
    return data;
}

/*
 * Serialize link object for request body.
 */
const LINK = (obj) => {
    const data = {'uri': obj.uri}
    if (obj.description != null) {
        data['description'] = obj.description;
    }
    if (obj.link_id != null) {
        data['link_id'] = obj.link_id;
    }
    return data;
}

/*
 * Serialize researcher object for request body.
 */
const RESEARCHER = (obj) => {
    const data = {
        'full_name': obj.name,
        'email': obj.email,
        'affiliations': obj.affiliations.map((a) => ({
            'affiliation_id': a.department.id,
            'status_id': a.role.id
        }))
    };
    if (obj.researcher_id != null) {
        data['researcher_id'] = obj.researcher_id;
    }
    return data;
}

/*
 * Serialize resource request object for request body.
 */
const RESOURCE = (obj) => {
    const data = {
        'expected_time': {
            'start_date': dateToStr(obj.startDate),
            'end_date': dateToStr(obj.endDate)
        },
        'weekly_hours': obj.hours,
        'skills': obj.skills.map((s) => ({
            'skill_id': s.skill.id,
            'skill_level_id': s.skillLevel.id,
            'mandatory': s.mandatory
        }))
    };
    if (obj.resource_rqmt_id != null) {
        data['resource_rqmt_id'] = obj.resource_rqmt_id;
    }
    return data;
}
