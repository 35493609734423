
/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import DataSummary from '../../widget/DataSummary';
import { FormatedObject, FormatedText, SkillListing } from '../../widget/SummaryHelpers';


const mapStateToProps = state => {
    return {
        worker: state.worker
    };
};

/*
 * Show summary of workers' education and skills.
 */
class QualificationSummary extends Component {
    render() {
        const { educations, skills } = this.props.worker.qualification;
        let content;
        if (educations.length > 0) {
            content = educations.map((edu, i) => {
                const { country, degreeStatus, degreeType, major, school } = edu;
                const values = [];
                values.push({
                    label: 'School Name',
                    value: <FormatedText text={school} message='School is missing' />
                });
                values.push({
                    label: 'Country',
                    value: <FormatedObject object={country} message='No country selected' />
                });
                values.push({
                    label: 'Major',
                    value: <FormatedText text={major} message='Major is missing' />
                });
                values.push({
                    label: 'Degree Type',
                    value: <FormatedObject object={degreeType} message='No degree type selected' />
                });
                values.push({
                    label: 'Degree Status',
                    value: <FormatedObject object={degreeStatus} message='No degree status selected' />
                });
                return (
                    <DataSummary
                        key={'edu_' + i}
                        className='prop-table'
                        elements={values}
                    />
                );
            });
        } else {
            content = [(<DataSummary
                key='edu'
                className='prop-table'
                elements={[{
                    label: 'Education',
                    value: <FormatedText message='Education is missing' />
                }]}
            />)];
        }
        content.push(
            <DataSummary
                key='skills'
                className='last-prop-table'
                elements={[{
                    label: 'Skills',
                    value: <SkillListing skills={skills} />
                }]}
            />
        );
        return (<Container>{ content }</Container>);
    }
}

export default connect(mapStateToProps)(QualificationSummary);
