/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/*
 * Collection of helper functions.
 */

/*
 * Get string representation for a date object. Based on:
 * https://stackoverflow.com/questions/23593052/
 */
export const dateToStr = (date) => {
    //const offset = date.getTimezoneOffset();
    //date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().split('T')[0];
}

export const parseDate = (date) => (new Date(Date.parse(date + 'T00:00:00')));

export const Today = () => (new Date(Date.now()))


/*
 * Delete the element at the given index position from the array. Returns a
 * copy of the array with the remaining elements.
 */
export const del = (elements, index) => {
    const result = [];
    elements.forEach((element, i) => {
        if (i !== index) {
            result.push(element);
        }
    });
    return result;
}


/*
 * Return the value of the name property of a given object. If the .id for the
 * object is -1 the object is considered an 'unselected' element from a select
 * dropdown. In this case, the returned value is the empty string.
 */
export const emptyIfUnselected = (object) => {
    if (object.id !== -1) {
        return object.name;
    } else {
        return '';
    }
}


/*
 * Test if a string is empty. If a string only contains white spaces it is
 * considered empty as well.
 */
export const isEmpty = (value) => {
    if (value == null) {
        return true;
    } else {
        return (value.trim() === '') ;
    }
}


/*
 * Check if the given string is empty. If true, return the string None else
 * return the given value.
 */
export const noneIfEmpty = (value) => {
    if ((value === '') || (value == null)) {
        return 'None';
    } else {
        return value;
    }
}


/*
 * Replace the element at the given index position in an element index.
 * Returns a new array where the element at the index position is replaced
 * with the element that is given as the third argument.
 */
export const replace = (elements, index, el) => {
    const result = [];
    for (let i = 0; i < elements.length; i++) {
        if (i === index) {
            result.push(el);
        } else {
            result.push(elements[i])
        }
    }
    return result;
}


/*
 * String representation for a time interval.
 */
export const timePeriod = (startDate, endDate) => {
    return dateToStr(startDate) + ' - ' + dateToStr(endDate);
}
