/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import '../app.css';


/*
 * Item in a list of editable objects in an input form.
 */
class ListItem extends Component {
    static propTypes = {
        header: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    }
    handleEdit = () => {
        const { index, onClick } = this.props;
        onClick(index);
    }
    render() {
        const { header, icon, name } = this.props;
        return (
            <List.Item style={{'textAlign': 'left'}}>
              <List.Icon name={icon} size='large' verticalAlign='middle' />
              <List.Content onClick={this.handleEdit}>
                <List.Header as='a'>{header}</List.Header>
                <List.Description as='a'>{name}</List.Description>
              </List.Content>
            </List.Item>
        );
    }
}


export default ListItem;
