/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input } from 'semantic-ui-react';
import ContextMenu from '../../widget/ContextMenu'
import ObjectListing from '../../widget/ObjectListing.js';
import SearchableDropdown from '../../widget/SearchableDropdown';
import Subheader from '../../widget/Subheader';
import { emptyIfUnselected } from '../../../resources/Util.js';
import 'react-datepicker/dist/react-datepicker.css';
import '../../app.css';
import '../project.css'


/*
 * Display label for researcher affiliation. Includes the department name and
 * the role name.
 */
const affiliationLabel = (affiliation) => {
    const { department, role } = affiliation;
    return emptyIfUnselected(department) + ' (' + emptyIfUnselected(role) + ')';
}


class ResearcherForm extends Component {
    static propTypes = {
        affiliations: PropTypes.array.isRequired,
        email: PropTypes.string.isRequired,
        masterdata: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        onAddAffiliation: PropTypes.func.isRequired,
        onClearAffiliation: PropTypes.func.isRequired,
        onDeleteAffiliation: PropTypes.func.isRequired,
        onDeleteResearcher: PropTypes.func,
        onUpdate: PropTypes.func.isRequired
    }
    /*
     * Event handler for changes in the department dropdown.
     */
    handleDepartmentUpdate = (department) => {
        this.props.onUpdate('selectedDepartment', department);
    }
    /*
     * Event handler for changes in the role dropdown.
     */
    handleRoleUpdate = (role) => {
        this.props.onUpdate('selectedRole', role);
    }
    /*
     * Render the input form for researcher information. For each researcher
     * we collect the full name and email, as well as a list of affiliations
     * and roles for the affiliation. The controlled vocabularies for
     * affiliations and affiliation roles are included in the global application
     * state (this.props.app.masterdata).
     */
    render() {
        const {
            affiliations,
            email,
            masterdata,
            name,
            onAddAffiliation,
            onClearAffiliation,
            onDeleteAffiliation,
            onDeleteResearcher,
            onUpdate,
            selectedDepartment,
            selectedRole
        } = this.props;
        const { departments, roles } = masterdata;
        let headerMenu = null;
        if (onDeleteResearcher != null) {
            headerMenu = (
                <ContextMenu
                    text='Delete Researcher'
                    onDelete={onDeleteResearcher}
                />
            );
        }
        return (
            <Form size='large'>
                { headerMenu }
                <Form.Field
                    control={Input}
                    label='Full Name'
                    onChange={(e) => onUpdate('name', e.target.value)}
                    placeholder="Full Name"
                    value={name}
                />
                <Form.Field
                    control={Input}
                    label='Email'
                    onChange={(e) => onUpdate('email', e.target.value)}
                    placeholder='Email'
                    value={email}
                />
                <Subheader title='Affiliations' />
                <ObjectListing
                    elements={affiliations}
                    getName={affiliationLabel}
                    onDelete={onDeleteAffiliation}
                />
                <Form.Group>
                    <Form.Field width={8}>
                        <SearchableDropdown
                            enableSearch
                            elements={departments}
                            selectedElement={selectedDepartment}
                            onUpdate={this.handleDepartmentUpdate}
                        />
                    </Form.Field>
                    <Form.Field width={6}>
                    <SearchableDropdown
                        enableSearch={false}
                        elements={roles}
                        selectedElement={selectedRole}
                        onUpdate={this.handleRoleUpdate}
                    />
                    </Form.Field>
                    <Form.Field width={1}>
                        <Button
                            icon='plus'
                            positive
                            disabled={selectedDepartment.id === -1 || selectedRole.id === - 1}
                            onClick={ onAddAffiliation }
                        />
                    </Form.Field>
                    <Form.Field width={1}>
                        <Button
                            icon='erase'
                            disabled={selectedDepartment.id === -1 && selectedRole.id === - 1}
                            onClick={ onClearAffiliation }
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }
}

export default ResearcherForm;
