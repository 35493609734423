/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Input, Label, Modal, Segment } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import '../project.css';


/*
 * Modal form for file uploads.
 */
class UploadFileModal extends Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {description: '', file: null};
    }
    render() {
        const { onCancel, onSubmit } = this.props;
        const { description, file } = this.state;
        let fileForm;
        if (file == null) {
            fileForm = (
                <Dropzone
                    onDrop={acceptedFiles => this.setStateProperty('file', acceptedFiles[0])}
                    multiple={false}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Segment>
                            <div className='upload-text'>
                                <Icon name='upload' size='large'/>
                                <p className='upload-text'>
                                    Drag files here, or click to select
                                </p>
                            </div>
                        </Segment>
                      </div>
                    </section>
                  )}
                </Dropzone>
            );
        } else {
            fileForm = (
                <Label size='large'>
                    <Icon name='file outline' />
                    { file.name }
                    <Icon
                        name='delete'
                        onClick={() => this.setStateProperty('file')}
                    />
                </Label>
            );
        }
        return (
            <Modal
                open={true}
                closeOnEscape={true}
                closeOnDimmerClick={false}
            >
                <Modal.Header>Upload File</Modal.Header>
                <Modal.Content>
                    <Form>
                        <div className='file-form'>
                            { fileForm }
                        </div>
                        <Form.Field
                            control={Input}
                            label='Description'
                            onChange={(e) => this.setStateProperty('description', e.target.value)}
                            placeholder='Description'
                            value={description}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancel} negative> Cancel </Button>
                <Button
                    onClick={() => (onSubmit(file, description))}
                    positive
                    disabled={file == null}
                    labelPosition='right'
                    icon='checkmark'
                    content='Attach'
                />
                </Modal.Actions>
            </Modal>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
}


export default UploadFileModal;
