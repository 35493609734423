/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    API_FETCH_ERROR, API_FETCH_START, API_FETCH_SUCCESS
} from '../actions/App';


/*
 * The application state maintains information about the API routes that are
 * available as well as the master data containing controlled vocabularies for
 * the static objects that describe properties of research projects and their
 * required resources. The sormat of the application state is:
 *
 * masterdata: {
 *   "affiliations": [{
 *     "id": 0,
 *     "name": "string"
 *   }],
 *   "affiliationRoles": [{
 *     "id": 0,
 *     "name": "string"
 *   }],
 *   "countries": [{
 *     "code": "string",
 *     "name": "string",
 *   }],
 *   "degreeStatus": [{
 *     "id": 0,
 *     "name": "string"
 *   }],
 *   "degreeTypes": [{
 *     "id": 0,
 *     "name": "string"
 *   }],
 *   "skills": [{
 *     "node_id": 0,
 *     "name": "string",
 *     "parents": [0]
 *   }],
 *   "skillLevels": [{
 *     "id": 0,
 *     "name": "string"
 *   }],
 *   "projectFundingStatus": [{
 *     "id": 0,
 *     "name": "string"
 *   }]
 * },
 * urls: resources.Urls()
 */
const INITIAL_STATE = {
    fetchError: null,
    isFetching: true,
    masterdata: null,
    urls: null
}


/*
 * Reducer for actions that signal state changes when fetching the API service
 * descriptor.
 */
const app = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case API_FETCH_ERROR:
            return {...state, fetchError: action.payload, isFetching: false};
        case API_FETCH_START:
            return {...state, isFetching: true};
        case API_FETCH_SUCCESS:
            const md = action.payload.masterdata;
            return {
                ...state,
                fetchError: null,
                isFetching: false,
                masterdata: {
                    affiliations: md.affiliations.sort((o1, o2) => (
                        o1.name.localeCompare(o2.name)
                    )),
                    affiliationStatus: md.affiliationStatus.sort((o1, o2) => (
                        o1.name.localeCompare(o2.name)
                    )),
                    countries: md.countries,
                    degreeStatus: md.degreeStatus,
                    degreeTypes: md.degreeTypes,
                    projectFundingStatus: md.projectFundingStatus,
                    skills: md.skills.sort((o1, o2) => (
                        o1.name.localeCompare(o2.name)
                    )),
                    skillLevels: md.skillLevels
                },
                urls: action.payload.urls
            };
        default:
            return state;
    }
}

export default app;
