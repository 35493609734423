/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { Urls } from '../resources/Urls';


// -- Actions -----------------------------------------------------------------

export const API_FETCH_ERROR = 'API_FETCH_ERROR';
export const API_FETCH_START = 'API_FETCH_START';
export const API_FETCH_SUCCESS = 'API_FETCH_SUCCESS';

// -- Service Descriptor ------------------------------------------------------

/*
 * The very first call to the API is to fetch the service descriptor.
 */
export function fetchApi() {
    const apiUrl = readApiUrl();
    return dispatch => {
        dispatch({type: API_FETCH_START});
        return fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => {
                        dispatch(fetchApiError(json.error));
                    });
                } else {
                    response.json().then(json => {
                        const urls = new Urls(apiUrl, json.routes);
                        return fetch(urls.masterdata())
                            .then(response => {
                                if (!response.ok) {
                                    response.json().then(json => {
                                        dispatch(fetchApiError(response.statusText));
                                    });
                                } else {
                                    response.json().then(json => {
                                        const payload = {
                                            urls,
                                            masterdata: json
                                        }
                                        dispatch(fetchApiSuccess(payload));
                                    })
                                }
                            })
                            .catch(error => dispatch(fetchApiError(error.message)));
                    })
                }
            })
            .catch(error => dispatch(fetchApiError(error.message)));
    };
}

function fetchApiSuccess(response) {
    return {type: API_FETCH_SUCCESS, payload: response};
}

function fetchApiError(message) {
    return {type: API_FETCH_ERROR, payload: message};
}

function readApiUrl() {
    // These variables depend on running the parsing environment script first.
    // Otherwise, there will be no 'environment.js' file to propagate the values.
    let host = window.env.WORKBENCH_API_HOST;
    let port = window.env.WORKBENCH_API_PORT;
    let path = window.env.WORKBENCH_API_PATH;

    path = trim_path_slashes(path);
    return `${host}:${port}/${path}`;
}

function trim_path_slashes(path) {
    if (path.startsWith('/')) {
        path = path.substring(1);
    }
    if (path.endsWith('/')) {
        path = path.substring(0, path.length-1);
    }
    return path;
}
