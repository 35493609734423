/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import '../app.css';


/*
 * Simple wrapper around an button that navigates to a given form
 */
class EditButton extends Component {
    static propTypes = {
        disabled: PropTypes.bool.isRequired,
        onClick: PropTypes.func.isRequired
    }
    render() {
        const { disabled, onClick } = this.props;
        return (
            <div className='form-action-buttons'>
                <Button
                    primary
                    disabled={disabled}
                    onClick={onClick}
                > Edit
                </Button>
            </div>
        );
    }
}

export default EditButton;
