/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Header, Message, Segment } from 'semantic-ui-react';
import EditButton from '../../widget/EditButton';
import ProjectDescription from '../summary/ProjectDescription';
import ResearcherListing from '../summary/ResearcherListing';
import ResourceListing from '../summary/ResourceListing';
import { dismissSubmitError, showForm } from '../../../actions/Project';
import {
    FORM_RESEARCHERS, FORM_DESCRIPTION, FORM_RESOURCES
} from '../../../resources/Project';
import '../../app.css';
import '../project.css';


const mapStateToProps = state => {
  return {
      project: state.project
  };
};


function mapDispatchToProps(dispatch) {
  return {
      dismissSubmitError: () => dispatch(dismissSubmitError()),
      navigate: (formId) => dispatch(showForm(formId))
  };
}


class SubmitForm extends Component {
    /*
     * Show a summary of all entered information. Also need to validate that
     * all required data has been entered. The project information is available
     * from the global application state (this.props.project).
     */
    render() {
        const { navigate, project } = this.props;
        const { isSubmitting, submitError } = project;
        // Display error message at bottom if there was a submission error.
        let message = null;
        if (submitError != null) {
            message = (
                <Message
                    icon='inbox'
                    header='There was an error'
                    content={ submitError }
                    negative
                    onDismiss={this.props.dismissSubmitError}
                />
            );
        }
        return (
            <Container fluid>
                <Header as='h3' style={{textAlign: 'left'}}> Principle Investigators </Header>
                <Segment>
                    <ResearcherListing />
                </Segment>
                <EditButton
                    disabled={isSubmitting}
                    onClick={() => (navigate(FORM_RESEARCHERS))}
                />
                <Header as='h3' style={{textAlign: 'left'}}> Project Description </Header>
                <Segment>
                    <ProjectDescription />
                </Segment>
                <EditButton
                    disabled={isSubmitting}
                    onClick={() => (navigate(FORM_DESCRIPTION))}
                />
                <Header as='h3' style={{textAlign: 'left'}}> Resource Requirements </Header>
                <Segment>
                    <ResourceListing />
                </Segment>
                <EditButton
                    disabled={isSubmitting}
                    onClick={() => (navigate(FORM_RESOURCES))}
                />
                { message }
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitForm);
