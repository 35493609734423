/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { parseDate } from './Util';


// Number of input forms
export const FORM_COUNT = 5;

export const FORM_CONTACT = 0;
export const FORM_QUALIFICATION = 1;
export const FORM_AVAILABILITY = 2;
export const FORM_SUBMIT = 3;
export const FORM_SUCCESS = 4;


/*
 * Convert worker handle returned by the API into the representation that is
 * expected by UI components that access the worker in the application state.
 */
export const Worker = (data, masterdata) => {
    const contact = {
        name: data.full_name,
        email: data.email
    };
    const qualification = {
        educations: data.educations.map((e) => ({
            education_id: e.education_id,
            school: e.school_name,
            major: e.major,
            country: masterdata.countries.find((c) => (e.country_code === c.id)),
            degreeStatus: masterdata.degreeStatus.find((d) => (e.degree_status_id === d.id)),
            degreeType: masterdata.degreeTypes.find((d) => (e.degree_type_id === d.id))
        })),
        skills: data.skills.map((obj) => ({
            skill: masterdata.skills.find((s) => (s.id === obj.skill_id)),
            skillLevel: masterdata.skillLevels.find((s) => (
                s.id === obj.skill_level_id
            )),
        }))

    };
    const availability = data.availabilities.map((a, i) => ({
        startDate: parseDate(a.start_date),
        endDate: parseDate(a.end_date),
        hours: a.weekly_hours.toString()
    }));
     return {
         workerId: data.worker_id,
         contact,
         qualification,
         availability
     };
 }
