/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal } from 'semantic-ui-react';


/*
 * Modal form for attaching a document by a link to a project.
 */
class ShareLinkModal extends Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props);
        this.state = {
            description: '',
            uri: ''
        }
    }
    /*
     * Show the modal form.
     */
    render() {
        const { onCancel, onSubmit } = this.props;
        const { description, uri } = this.state;
        return (
            <Modal
                open={true}
                closeOnEscape={true}
                closeOnDimmerClick={false}
            >
                <Modal.Header>Attach Link to Shared Document</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field
                            control={Input}
                            label='URL'
                            onChange={(e) => this.setStateProperty('uri', e.target.value)}
                            placeholder='URL'
                            value={uri}
                        />
                        <Form.Field
                            control={Input}
                            label='Description'
                            onChange={(e) => this.setStateProperty('description', e.target.value)}
                            placeholder='Description'
                            value={description}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={onCancel} negative> Cancel </Button>
                <Button
                    onClick={() => (onSubmit(uri, description))}
                    positive
                    disabled={uri == null || uri === ''}
                    labelPosition='right'
                    icon='checkmark'
                    content='Attach'
                />
                </Modal.Actions>
            </Modal>
        )
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
}


export default ShareLinkModal;
