/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { dateToStr } from '../resources/Util';
import { Worker } from '../resources/Worker';


// -- Action Identifier -------------------------------------------------------

export const CREATE_NEW_WORKER = 'CREATE_NEW_WORKER'
export const SHOW_WORKER_FORM = 'SHOW_WORKER_FORM';
export const UPDATE_WORKER_AVAILABILITY = 'UPDATE_WORKER_AVAILABILITY'
export const UPDATE_WORKER_CONTACT = 'UPDATE_WORKER_CONTACT'
export const UPDATE_WORKER_QUALIFICATION = 'UPDATE_WORKER_QUALIFICATION'
export const WORKER_FETCH_ERROR = 'WORKER_FETCH_ERROR';
export const WORKER_FETCH_START = 'WORKER_FETCH_START';
export const WORKER_FETCH_SUCCESS = 'WORKER_FETCH_SUCCESS';
export const WORKER_SUBMIT_ERROR = 'WORKER_SUBMIT_ERROR';
export const WORKER_SUBMIT_START = 'WORKER_SUBMIT_START';
export const WORKER_SUBMIT_SUCCESS = 'WORKER_SUBMIT_SUCCESS';



// -- Actions to fetch worker data --------------------------------------------

export const fetchWorker = (url, masterdata) => {
    return dispatch => {
        dispatch({type: WORKER_FETCH_START});
        return fetch(url)
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => (
                        dispatch(fetchErrorHandler(json.message))
                    ));
                } else {
                    response.json().then(json => (
                        dispatch(fetchSuccessHandler(Worker(json, masterdata)))
                    ));
                }
            })
    }
}

const fetchErrorHandler = (msg) => {
    return {type: WORKER_FETCH_ERROR, payload: msg}
};


const fetchSuccessHandler = (json) => {
    return {type: WORKER_FETCH_SUCCESS, payload: json}
};


// -- Actions that update the global project state ----------------------------

/*
 * Update the list of availability information for the worker. Expects
 * an array of objects that have the following format:
 *
 * [{
 *   "startDate": Date,
 *   "endDate": Date,
 *   "hours": 0
 * }]
 */
export const updateWorkerAvailability = (data) => ({
    type: UPDATE_WORKER_AVAILABILITY,  payload: data
})

/*
 * Update the worker contact information. Expects a data object that has the
 * following format:
 *
 * {
 *   "name": "string",
 *   "email": "string"
 * }
 */
export const updateWorkerContact = (data) => ({
    type: UPDATE_WORKER_CONTACT, payload: data
})


/*
 * Update the qualification information for the worker. Expects a data object
 * that has the following format:
 *
 * {
 *   "educations": [{
 *     "name": "string",
 *     "major": "string",
 *     "country": {"id": "string", "name": "string"},
 *     "degreeType_id": {"id": 0, "name": "string"},
 *     "degreeStatus": {"id": 0, "name": "string"}
 *   }],
 *   "skills": [{
 *     "skill": {"id": 0, "name": "string"},
 *     "skillLevel": {"id": 0, "name": "string"}
 *   }]
 * }
 */
export const updateWorkerQualification = (data) => ({
    type: UPDATE_WORKER_QUALIFICATION, payload: data
})


// -- Actions to submit project information to the API ------------------------

export const submitWorker = (worker, urls) => {
    const { contact, qualification, availability } = worker;
    const { educations, skills } = qualification;
    // Create the request body.
    const data = {
        'full_name': contact.name,
        'email': contact.email,
        'educations': educations.map((e) => (EDUCATION(e))),
        'skills': skills.map((r) => (SKILL(r))),
        'availabilities': availability.map((a) => (SCHEDULE(a)))
    };
    // The API endpoint depends on whether a worker id is present (indicating
    // an update to an existing worker) or not (create a new worker).
    let url;
    if (worker.workerId != null) {
        data['worker_id'] = worker.workerId;
        url = urls.workersUpdate(worker.workerId);
    } else {
        url = urls.workersCreate();
    }
    // Submit request.
    const headers = {'Content-Type': 'application/json'};
    const body = JSON.stringify(data);
    console.log(data);
    return dispatch => {
        dispatch({type: WORKER_SUBMIT_START});
        return fetch(url, {method: 'POST', headers, body})
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => {
                        dispatch(submitErrorHandler(json.message));
                    });
                } else {
                    response.json().then(json => {
                        dispatch(submitSuccessHandler(json));
                    })
                }
            })
            .catch(error => dispatch(submitErrorHandler(error.message)))
    }
}


const submitErrorHandler = (msg) => {
    return {type: WORKER_SUBMIT_ERROR, payload: msg}
};

const submitSuccessHandler = (json) => {
    return {type: WORKER_SUBMIT_SUCCESS, payload: json}
};

export const dismissSubmitError = () => (submitErrorHandler());

// -- Actionas to navigate input forms ----------------------------------------

export function showForm(formId) {
    return {type: SHOW_WORKER_FORM, payload: formId};
}


// -- Object serializers ------------------------------------------------------

/*
 * Serialize worker education degree object for request body.
 */
const EDUCATION = (obj) => {
    const data = {
        'school_name': obj.school,
        'major': obj.major,
        'country_code': obj.country.id,
        'degree_type_id': obj.degreeType.id,
        'degree_status_id': obj.degreeStatus.id
    };
    if (obj.education_id != null) {
        data['education_id'] = obj.education_id;
    }
    return data;
}


/*
 * Serialize worker availability object for request body.
 */
const SCHEDULE = (obj) => {
    const data = {
        'start_date': dateToStr(obj.startDate),
        'end_date': dateToStr(obj.endDate),
        'weekly_hours': obj.hours
    };
    if (obj.availability_id != null) {
        data['availability_id'] = obj.availability_id;
    }
    return data;
}


/*
 * Serialize worker skill selection request body.
 */
const SKILL = (obj) => ({
    'skill_id': obj.skill.id,
    'skill_level_id': obj.skillLevel.id
})
