/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Form, Header, Icon, List } from 'semantic-ui-react';
import EducationForm from '../subform/EducationForm.js';
import ListItem from '../../widget/ListItem';
import ObjectListing from '../../widget/ObjectListing';
import SearchableDropdown from '../../widget/SearchableDropdown';
import { updateWorkerQualification } from '../../../actions/Worker';
import {
    UNSELECTED_COUNTRY, UNSELECTED_DEGREE_STATUS, UNSELECTED_DEGREE_TYPE
} from '../../../resources/Education';
import { UNSELECT_SKILL, UNSELECT_SKILLLEVEL } from '../../../resources/Skill';
import { skillLabel } from '../../../resources/Skill';
import { del, noneIfEmpty, replace } from '../../../resources/Util'
import '../../app.css'
import '../worker.css'


const mapStateToProps = state => {
  return {
      app: state.app,
      worker: state.worker
  };
};


function mapDispatchToProps(dispatch) {
  return {
      updateState: (data) => dispatch(updateWorkerQualification(data))
  };
}


class QualificationForm extends Component {
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintain project
        // information. Add additional elements here if required by the form.
        this.state = {
            educations: [],
            major: '',
            school: '',
            selectedCountry: UNSELECTED_COUNTRY(),
            selectedDegreeStatus: UNSELECTED_DEGREE_STATUS(),
            selectedDegreeType: UNSELECTED_DEGREE_TYPE(),
            selectedEducation: -1,
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL(),
            skills: []
        };
    }
    /*
     * Initialize the local form state from the global project state when the
     * component mounts.
     */
    componentDidMount() {
        const qualification = this.props.worker.qualification;
        if (qualification != null) {
            const { educations, skills } = qualification;
            if (educations.length > 0) {
                const edu = educations[educations.length - 1];
                this.setState({
                    educations: educations,
                    school: edu.school,
                    major: edu.major,
                    selectedCountry: edu.country,
                    selectedDegreeStatus: edu.degreeStatus,
                    selectedDegreeType: edu.degreeType,
                    selectedEducation: educations.length - 1,
                    skills: skills
                });
            } else {
                this.setState({
                    educations: educations,
                    selectedEducation: -1,
                    skills: skills
                });
            }
        }
    }
    /*
     * Update the values that maintain the form state in the global application
     * state.
     */
    componentWillUnmount() {

        // Construct an object (from the component state) that is a dictionary
        // object with the following format:
        //
        // "educations": [{
        //   "school": "string",
        //   "major": "string",
        //   "country": {"id": "string", "name": "string"},
        //   "degreeType": {"id": 0, "name": "string"},
        //   "degreeStatus": {"id": 0, "name": "string"}
        // }],
        // "skills": [{
        //   "skill": {"id": 0, "name": "string"},
        //   "skillLevel: {"id": 0, "name": "string"}
        // }]

        const qualification = {
            educations: this.updateEducationList(),
            skills: this.updateSkillsList()
        }
        this.props.updateState(qualification);
    }
    /*
     * Add a new element to the education list.
     */
    addEducation = () => {
        this.setState({
            school: '',
            major: '',
            educations: this.updateEducationList(),
            selectedCountry: UNSELECTED_COUNTRY(),
            selectedDegreeStatus: UNSELECTED_DEGREE_STATUS(),
            selectedDegreeType: UNSELECTED_DEGREE_TYPE(),
            selectedEducation: -1,
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL()
        });
    }
    /*
     * Add the selected skill and skill level to the list of skills for the
     * worker.
     */
    addSelectedSkill = () => {
        this.setState({
            skills: this.state.skills.concat([{
                skill: this.state.selectedSkill,
                skillLevel: this.state.selectedSkillLevel
            }]),
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL(),
        })
    }
    /*
    * Clear selections in the worker skills form.
    */
    clearSkillForm = () => {
        this.setState({
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL()
        })
    }
    /*
     * Delete the education object that is currently selected.
     */
    deleteEducation = () => {
        const { educations, selectedEducation } = this.state;
        const updEducations = del(educations, selectedEducation);
        let education = null;
        let newSelection = -1;
        if (updEducations.length > 0) {
            newSelection = selectedEducation;
            if (newSelection >= updEducations.length) {
                newSelection--;
            } else if (newSelection < 0) {
                newSelection = 0;
            }
            education = updEducations[newSelection];
        } else {
            education = {
                school: '',
                major: '',
                selectedCountry: UNSELECTED_COUNTRY(),
                degreeStatus: UNSELECTED_DEGREE_STATUS(),
                degreeType: UNSELECTED_DEGREE_TYPE()
            };
        }
        this.setState({
            educations: updEducations,
            major: education.major,
            school: education.school,
            selectedCountry: education.country,
            selectedEducation: newSelection,
            selectedDegreeStatus: education.degreeStatus,
            selectedDegreeType: education.degreeType,
        });
    }
    /*
     * Delete the given skii from the list of worker skills. Objects are
     * referenced by their index position in the skills list.
     */
    deleteSelectedSkill = (index) => {
        this.setState({skills: del(this.state.skills, index)});
    }
    /*
     * Edit the education object at the given position in the array.
     */
    editEducation = (index) => {
        const education = this.state.educations[index];
        this.setState({
            educations: this.updateEducationList(),
            major: education.major,
            school: education.school,
            selectedCountry: education.country,
            selectedEducation: index,
            selectedDegreeStatus: education.degreeStatus,
            selectedDegreeType: education.degreeType,
        });
    }
    /*
     * Event handler for changes in the skill dropdown.
     */
    handleSkillUpdate = (skill) => {
        this.setStateProperty('selectedSkill', skill);
    }
    /*
     * Event handler for changes in the skill level dropdown.
     */
    handleSkillLevelUpdate = (skill) => {
        this.setStateProperty('selectedSkillLevel', skill);
    }
    /*
     * Render the input form for worker education and skill information.
     */
    render() {
        const {
            educations,
            major,
            school,
            selectedCountry,
            selectedEducation,
            selectedDegreeStatus,
            selectedDegreeType,
            selectedSkill,
            selectedSkillLevel,
            skills
        } = this.state;
        const masterdata = this.props.app.masterdata;
        // Show the delete education option in the header menu if there is
        // more than one education in the list.
        let callbackDelete = null;
        if (!(((selectedEducation === 0) && (educations.length === 1)) || (educations.length === 0))) {
            callbackDelete = this.deleteEducation;
        }
        let newEducationForm = null;
        if (selectedEducation === -1) {
            newEducationForm = (
                <List.Item key={-1}>
                    <EducationForm
                        major={major}
                        masterdata={masterdata}
                        onDelete={callbackDelete}
                        onUpdate={this.setStateProperty}
                        school={school}
                        selectedCountry={selectedCountry}
                        selectedDegreeStatus={selectedDegreeStatus}
                        selectedDegreeType={selectedDegreeType}
                    />
                </List.Item>
            );
        }
        return (
            <Container fluid>
                <Header as='h2' textAlign='left'>Education</Header>
                <List divided relaxed size='big'>
                    {educations.map((obj, i) => {
                        if (i === selectedEducation) {
                            return (
                                <List.Item key={i}>
                                    <EducationForm
                                        major={major}
                                        masterdata={masterdata}
                                        onDelete={callbackDelete}
                                        onUpdate={this.setStateProperty}
                                        school={school}
                                        selectedCountry={selectedCountry}
                                        selectedDegreeStatus={selectedDegreeStatus}
                                        selectedDegreeType={selectedDegreeType}
                                    />
                                </List.Item>
                            );
                        } else {
                            return (
                                <ListItem
                                    key={i}
                                    index={i}
                                    icon='graduation cap'
                                    header={noneIfEmpty(obj.school)}
                                    name={noneIfEmpty(obj.major)}
                                    onClick={this.editEducation}
                                />
                            );
                        }
                    })}
                    { newEducationForm }
                </List>
                <div className='form-action-buttons'>
                    <Button
                        icon
                        labelPosition='left'
                        primary
                        size='small'
                        onClick={this.addEducation}
                    >
                        <Icon name='graduation cap' />
                        Add Education
                    </Button>
                </div>
                <Header as='h2' textAlign='left'>Skills</Header>
                <Form size='large'>
                    <ObjectListing
                        elements={skills}
                        getName={skillLabel}
                        onDelete={this.deleteSelectedSkill}
                    />
                    <Form.Group>
                        <Form.Field width={7}>
                            <SearchableDropdown
                                enableSearch
                                elements={masterdata.skills}
                                selectedElement={selectedSkill}
                                onUpdate={this.handleSkillUpdate}
                            />
                        </Form.Field>
                        <Form.Field width={7}>
                            <SearchableDropdown
                                enableSearch={false}
                                elements={masterdata.skillLevels}
                                selectedElement={selectedSkillLevel}
                                onUpdate={this.handleSkillLevelUpdate}
                            />
                        </Form.Field>
                        <Form.Field width={1}>
                            <Button
                                icon='plus'
                                positive
                                disabled={selectedSkill.id === -1 || selectedSkillLevel.id === - 1}
                                onClick={ this.addSelectedSkill }
                            />
                        </Form.Field>
                        <Form.Field width={1}>
                            <Button
                                icon='erase'
                                disabled={selectedSkill.id === -1 && selectedSkillLevel.id === - 1}
                                onClick={ this.clearSkillForm }
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Container>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    /*
     * Create a researcher object from the current form state.
     */
    updateEducationList = () => {
        // Construct an object (from the component state) that is a list of
        // education objects with the following format:
        //
        // - "school": "string",
        //   "major": :string",
        //   "country": {"id": "integer", "name": "string"}
        //   "degreeStatus": {"id": "integer", "name": "string"}
        //   "degreeType": {"id": "integer", "name": "string"}
        const {
            educations,
            major,
            selectedCountry,
            selectedDegreeStatus,
            selectedDegreeType,
            selectedEducation,
            school
        } = this.state;
        const education = {
            school,
            major,
            country: selectedCountry,
            degreeStatus: selectedDegreeStatus,
            degreeType: selectedDegreeType
        }
        // Depending on the value of the currently selected education item the
        // education item that is being edited is either appended to the list
        // or the item in the list is replaced.
        if (selectedEducation === -1) {
            return [...educations, education];
        } else {
            return replace(educations, selectedEducation, education);
        }
    }
    /*
     * Update the list of skills by adding the current form values if either
     * one of them is selected.
     */
    updateSkillsList = () => {
        const { skills, selectedSkill, selectedSkillLevel } = this.state;
        if ((selectedSkill.id !== -1) || (selectedSkillLevel.id !== -1)) {
            return skills.concat({
                skill: selectedSkill,
                skillLevel: selectedSkillLevel
            })
        } else {
            return skills;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualificationForm);
