/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Icon, List } from 'semantic-ui-react';
import ListItem from '../../widget/ListItem';
import ResearcherForm from '../subform/ResearcherForm';
import { updateProjectResearchers } from "../../../actions/Project";
import { UNSELECTED_DEPARTMENT, UNSELECTED_ROLE } from '../../../resources/Affiliation';
import { del, noneIfEmpty, replace } from '../../../resources/Util';
import '../project.css'


const mapStateToProps = state => {
  return {
      app: state.app,
      project: state.project
  };
};

function mapDispatchToProps(dispatch) {
  return {
      updateState: (data) => dispatch(updateProjectResearchers(data))
 };
}


class PrincipleInvestigatorForm extends Component {
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintains the list of
        // principle investigators for the project and the state of the input
        // form for the researcher that is currently being edited.
        this.state = {
          name: "",
          email: "",
          affiliations: [],
          selectedDepartment: UNSELECTED_DEPARTMENT(),
          selectedResearcher: -1,
          selectedRole: UNSELECTED_ROLE(),
          researchers: []
        };
    }
    /*
     * Initialize the local form state from the global project state when the
     * component mounts.
     */
    componentDidMount() {
        // Get list of researchers. If the list is not empty we edit the last
        // researcher in the list by default.
        const researchers = this.props.project.researchers;

        if (researchers.length > 0) {
            const selectedResearcher = researchers.length - 1;
            const lastResearcher = researchers[selectedResearcher];
            this.setState({
                name: lastResearcher.name,
                email: lastResearcher.email,
                affiliations: lastResearcher.affiliations,
                selectedDepartment: UNSELECTED_DEPARTMENT(),
                selectedResearcher: selectedResearcher,
                selectedRole: UNSELECTED_ROLE(),
                researchers: researchers
            })
        }
    }
    /*
     * Update the form state in the global application state.
     */
    componentWillUnmount() {
        this.props.updateState(this.updateResearcherList());
    }
    /*
     * Add a new researcher to the list. Displays the researcher form at the
     * end of the current form.
     */
    addResearcher = () => {
        this.setState({
            name: '',
            email: '',
            affiliations: [],
            selectedDepartment: UNSELECTED_DEPARTMENT(),
            selectedResearcher: -1,
            selectedRole: UNSELECTED_ROLE(),
            researchers: this.updateResearcherList()
        });
    }
    /*
     * Add the selected department and affiliation role to the list of
     * affiliations for the current researcher.
     */
    addSelectedAffiliation = () => {
        this.setState({
            affiliations: this.state.affiliations.concat([{
                department: this.state.selectedDepartment,
                role: this.state.selectedRole
            }]),
            selectedDepartment: UNSELECTED_DEPARTMENT(),
            selectedRole: UNSELECTED_ROLE(),
        })
    }
    /*
    * Clear selections in the affiliation form.
    */
    clearAffiliationForm = () => {
        this.setState({
            selectedDepartment: UNSELECTED_DEPARTMENT(),
            selectedRole: UNSELECTED_ROLE(),
        })
    }
    /*
     * Delete the researcher that is currently selected.
     */
    deleteResearcher = () => {
        const { researchers, selectedResearcher } = this.state;
        const updResearchers = del(researchers, selectedResearcher);
        let researcher = null;
        let newSelection = -1;
        if (updResearchers.length > 0) {
            newSelection = selectedResearcher;
            if (newSelection >= updResearchers.length) {
                newSelection--;
            } else if (newSelection < 0) {
                newSelection = 0;
            }
            researcher = updResearchers[newSelection];
        } else {
            researcher = {name: '', email: '', affiliations: []};
        }
        this.setState({
            name: researcher.name,
            email: researcher.email,
            affiliations: researcher.affiliations,
            selectedDepartment: UNSELECTED_DEPARTMENT(),
            selectedResearcher: newSelection,
            selectedRole: UNSELECTED_ROLE(),
            researchers: updResearchers
        });
    }
    /*
     * Delete the given affiliation from the list of affiliations for the
     * current researcher.
     */
    deleteSelectedAffiliation = (index) => {
        this.setState({affiliations: del(this.state.affiliations, index)});
    }
    /*
     * Edit the researcher at the given position in the researcher array.
     */
    editResearcher = (index) => {
        const researcher = this.state.researchers[index];
        this.setState({
            name: researcher.name,
            email: researcher.email,
            affiliations: researcher.affiliations,
            selectedDepartment: UNSELECTED_DEPARTMENT(),
            selectedResearcher: index,
            selectedRole: UNSELECTED_ROLE(),
            researchers: this.updateResearcherList()
        });
    }
    /*
     * Render the input form for researcher information. For each researcher
     * we collect the full name and email, as well as a list of affiliations
     * and roles for the affiliation. The controlled vocabularies for
     * affiliations and affiliation roles are included in the global application
     * state (this.props.app.masterdata).
     */
    render() {
        const {
            affiliations,
            email,
            name,
            researchers,
            selectedDepartment,
            selectedResearcher,
            selectedRole
        } = this.state;
        const departments = this.props.app.masterdata.affiliations;
        const roles = this.props.app.masterdata.affiliationStatus;
        const masterdata = {departments, roles};
        // Show the delete researcher option in the header menu if there is
        // more than one researcher in the list.
        let callbackDeleteResearcher = null;
        if (!(((selectedResearcher === 0) && (researchers.length === 1)) || (researchers.length === 0))) {
            callbackDeleteResearcher = this.deleteResearcher;
        }
        let newResearcherForm = null;
        if (selectedResearcher === -1) {
            newResearcherForm = (
                <List.Item key={-1}>
                    <ResearcherForm
                        affiliations={affiliations}
                        email={email}
                        masterdata={masterdata}
                        name={name}
                        onAddAffiliation={this.addSelectedAffiliation}
                        onClearAffiliation={this.clearAffiliationForm}
                        onDeleteAffiliation={this.deleteSelectedAffiliation}
                        onDeleteResearcher={callbackDeleteResearcher}
                        onUpdate={this.setStateProperty}
                        selectedDepartment={selectedDepartment}
                        selectedRole={selectedRole}
                    />
                </List.Item>
            );
        }
        return (
            <Container fluid>
                <List divided relaxed size='big'>
                    {researchers.map((obj, i) => {
                        if (i === selectedResearcher) {
                            return (
                                <List.Item key={i}>
                                    <ResearcherForm
                                        affiliations={affiliations}
                                        email={email}
                                        name={name}
                                        masterdata={masterdata}
                                        onAddAffiliation={this.addSelectedAffiliation}
                                        onClearAffiliation={this.clearAffiliationForm}
                                        onDeleteAffiliation={this.deleteSelectedAffiliation}
                                        onDeleteResearcher={callbackDeleteResearcher}
                                        onUpdate={this.setStateProperty}
                                        selectedDepartment={selectedDepartment}
                                        selectedRole={selectedRole}
                                    />
                                </List.Item>
                            );
                        } else {
                            return (
                                <ListItem
                                    key={i}
                                    index={i}
                                    icon='user secret'
                                    header={noneIfEmpty(obj.name)}
                                    name={noneIfEmpty(obj.email)}
                                    onClick={this.editResearcher}
                                />
                            );
                        }
                    })}
                    { newResearcherForm }
                </List>
                <div className='form-action-buttons'>
                    <Button
                        icon
                        labelPosition='left'
                        primary
                        size='small'
                        onClick={this.addResearcher}
                    >
                        <Icon name='user' />
                        Add Researcher
                    </Button>
                </div>
            </Container>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    /*
     * Create a researcher object from the current form state.
     */
    updateResearcherList = () => {
        // Construct an object (from the component state) that is a list of
        // researcher objects with the following format:
        //
        // - name: "string"
        // - email: "string"
        // - affiliations: [{
        //   - department: {"id": "integer", "name": "string"}
        //   - role: {"id": "integer", "name": "string"}
        //
        // }]
        const {
            name,
            email,
            affiliations,
            selectedDepartment,
            selectedRole
        } = this.state;
        // Add the current value in the affiliation form if at least one of the
        // dropdowns has a selected value.
        const updAffiliations = [...affiliations];
        if ((selectedDepartment.id !== -1) || (selectedRole.id !== -1)) {
            updAffiliations.push({
                department: selectedDepartment,
                role: selectedRole
            });
        }
        const researcher = {
            name: name,
            email: email,
            affiliations: updAffiliations
        }
        // Depending on the value of the currently selected researcher the
        // researcher that is being edited is either appended to the list or
        // the item in the list is replaced.
        const { researchers, selectedResearcher } = this.state;
        if (selectedResearcher === -1) {
            return [...this.state.researchers, researcher];
        } else {
            return replace(researchers, selectedResearcher, researcher);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrincipleInvestigatorForm);
