/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import ContextMenu from '../../widget/ContextMenu';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../app.css';
import '../worker.css';


class ScheduleForm extends Component {
    static propTypes = {
        endDate: PropTypes.instanceOf(Date).isRequired,
        hours: PropTypes.string.isRequired,
        onDelete: PropTypes.func,
        onUpdate: PropTypes.func.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired
    }
    /*
     * Event handler for changes in the end date picker.
     */
    handleEndDateUpdate = (date) => {
        this.props.onUpdate('endDate', date);
    }
    /*
     * Event handler for changes in the hours text field.
     */
    handleHoursUpdate = (event) => {
        this.props.onUpdate('hours', event.target.value);
    }
    /*
     * Event handler for changes in the start date picker.
     */
    handleStartDateUpdate = (date) => {
        this.props.onUpdate('startDate', date);
    }
    /*
     * Render the input form for worker education information. The
     * controlled vocabularies for country code, degree type, degree status,
     * skill name, and skill proficiency are included in the masterdata
     * that is part of the application state (this.props.app.masterdata).
     */
    render() {
        const {endDate, hours, onDelete, startDate } = this.props;
        // The header menu is only displayed if the deletion handler is given.
        let headerMenu = null;
        if (onDelete != null) {
            headerMenu = (
                <ContextMenu
                    text='Delete Availability'
                    onDelete={onDelete}
                />
            );
        }
        return (
            <Form size='large'>
                { headerMenu }
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Start date</label>
                        <DatePicker
                            showPopperArrow={false}
                            popperPlacement="bottom"
                            popperModifiers={{flip: {behavior: ["bottom"]}}}
                            onChange={this.handleStartDateUpdate}
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>End date</label>
                        <DatePicker
                            showPopperArrow={false}
                            popperPlacement="bottom"
                            popperModifiers={{flip: {behavior: ["bottom"]}}}
                            onChange={this.handleEndDateUpdate}
                            dateFormat="yyyy-MM-dd" selected={endDate}
                        />
                    </Form.Field>
                    <Form.Input
                        label="Weekly hours"
                        placeholder="20"
                        className="last"
                        value={hours}
                        onChange={this.handleHoursUpdate}
                    />
                </Form.Group>
            </Form>
        );
    }
}

export default ScheduleForm;
