/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/*
 * This class is a wrapper around route information contained in the API
 * Service descriptor. It provides access to the relevant routes for the
 * external UI components.
 */
export class Urls {
    constructor(baseUrl, routes) {
        this.baseUrl = baseUrl;
        this.routes = routes;
    }
    /*
     * Get the Url pattern reference that is associated with the given action
     * identifier. The result is null if no reference with the given key
     * exists.
     */
    get(key) {
        const ref = this.routes.find((ref) => (ref.id === key));
        if (ref != null) {
            return this.baseUrl + ref.pattern;
        } else {
            return null;
        }
    }
    /*
     * Get Url for file uploads.
     */
    fileUpload() {
        return this.get('files:upload');
    }
    /*
     * Get master data dictionaries for the external UI.
     */
    masterdata() {
        return this.get('masterdata:external');
    }
    /*
    * Get Url to POST data for new projects.
    */
    projectsCreate() {
        return this.get('projects:create');
    }
    /*
    * Get Url to GET handle for existing project.
    */
    projectsFetch(projectId) {
        return this.get('projects:get').replace('{projectId}', projectId);
    }
    /*
    * Get Url to POST update for existing project.
    */
    projectsUpdate(projectId) {
        return this.get('projects:update').replace('{projectId}', projectId);
    }
    /*
    * Get Url to POST data for new projects.
    */
    workersCreate() {
        return this.get('workers:create');
    }
    /*
    * Get Url to GET handle for existing worker.
    */
    workersFetch(workerId) {
        return this.get('workers:get').replace('{workerId}', workerId);
    }
    /*
    * Get Url to POST updated handle for existing worker.
    */
    workersUpdate(workerId) {
        return this.get('workers:update').replace('{workerId}', workerId);
    }
}
