/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import { Button, Checkbox, Form } from 'semantic-ui-react';
import ContextMenu from '../../widget/ContextMenu'
import SearchableDropdown from '../../widget/SearchableDropdown';
import Subheader from '../../widget/Subheader';
import ObjectListing from '../../widget/ObjectListing.js';
import { skillLabel, skillIcon } from '../../../resources/Skill';
import 'react-datepicker/dist/react-datepicker.css';
import '../../app.css';
import '../project.css';


class ResourceForm extends Component {
    static propTypes = {
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
        hours: PropTypes.string.isRequired,
        mandatory: PropTypes.bool.isRequired,
        skills: PropTypes.array.isRequired,
        masterdata: PropTypes.object.isRequired,
        selectedSkill: PropTypes.object.isRequired,
        selectedSkillLevel: PropTypes.object.isRequired,
        onAddSkill: PropTypes.func.isRequired,
        onClearSkill: PropTypes.func.isRequired,
        onDeleteResource: PropTypes.func,
        onDeleteSkill: PropTypes.func.isRequired,
        onUpdate: PropTypes.func.isRequired
    }
    /*
     * Event handler for changes in the skill dropdown.
     */
    handleSkillUpdate = (skill) => {
        this.props.onUpdate('selectedSkill', skill);
    }
    /*
     * Event handler for changes in the skill level dropdown.
     */
    handleSkillLevelUpdate = (skill) => {
        this.props.onUpdate('selectedSkillLevel', skill);
    }
    /*
     * Render the input form for project resource information. For each
     * resource the time period and list of required skills are maintained.
     * For each skill we maintain the skill identifier as well as the requested
     * skill level. The controlled vocabularies for skills and skill levels are
     * included in the global application state (this.props.app.masterdata).
     */
    render() {
        const {
            startDate,
            endDate,
            hours,
            mandatory,
            skills,
            masterdata,
            selectedSkill,
            selectedSkillLevel,
            onAddSkill,
            onClearSkill,
            onDeleteResource,
            onDeleteSkill,
            onUpdate
        } = this.props;
        const { skillTaxonomy, skillLevels } = masterdata;
        let headerMenu = null;
        if (onDeleteResource != null) {
            headerMenu = (
                <ContextMenu
                    text='Delete Resource'
                    onDelete={onDeleteResource}
                />
            );
        }
        return (
            <Form size='large'>
                { headerMenu }
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Expected start date</label>
                        <DatePicker
                            showPopperArrow={false}
                            popperPlacement="bottom"
                            popperModifiers={{flip: {behavior: ["bottom"]}}}
                            onChange={(e) => onUpdate('startDate', e)}
                            dateFormat="yyyy-MM-dd"
                            selected={startDate}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Expected end date</label>
                        <DatePicker
                            showPopperArrow={false}
                            popperPlacement="bottom"
                            popperModifiers={{flip: {behavior: ["bottom"]}}}
                            onChange={(e) => onUpdate('endDate', e)}
                            dateFormat="yyyy-MM-dd" selected={endDate}
                        />
                    </Form.Field>
                    <Form.Input
                        label="Weekly hours"
                        placeholder="20"
                        className="last"
                        value={hours}
                        onChange={(e) => onUpdate('hours', e.target.value)}
                    />
                </Form.Group>
                <Subheader title='Skills' />
                <ObjectListing
                    elements={skills}
                    getIcon={skillIcon}
                    getName={skillLabel}
                    onDelete={onDeleteSkill}
                />
                <Form.Group>
                    <Form.Field width={6}>
                        <SearchableDropdown
                            enableSearch
                            elements={skillTaxonomy}
                            selectedElement={selectedSkill}
                            onUpdate={this.handleSkillUpdate}
                        />
                    </Form.Field>
                    <Form.Field width={5}>
                        <SearchableDropdown
                            enableSearch={false}
                            elements={skillLevels}
                            selectedElement={selectedSkillLevel}
                            onUpdate={this.handleSkillLevelUpdate}
                        />
                    </Form.Field>
                    <Form.Field width={3}>
                        <Checkbox
                            className='large-text'
                            label='Mandatory'
                            checked={mandatory}
                            onClick={(e) => onUpdate('mandatory', !mandatory)}
                        />
                    </Form.Field>
                    <Form.Field width={1}>
                        <Button
                            icon='plus'
                            positive
                            disabled={selectedSkill.id === -1 || selectedSkillLevel.id === - 1}
                            onClick={ onAddSkill }
                        />
                    </Form.Field>
                    <Form.Field width={1}>
                        <Button
                            icon='erase'
                            disabled={selectedSkill.id === -1 && selectedSkillLevel.id === - 1}
                            onClick={ onClearSkill }
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }
}

export default ResourceForm;
