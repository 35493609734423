/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { emptyIfUnselected } from './Util.js';

/*
 * Display label for resource skills. Contains the skill name and level.
 */
export const skillLabel = ({skill, skillLevel, mandatory}) => {
    return emptyIfUnselected(skill) + ' (' + emptyIfUnselected(skillLevel) + ')';
}


/*
 * Display a chacked circle icon for skills that are mandatory.
 */
export const skillIcon = ({skill, skillLevel, mandatory}) => {
    if (mandatory === true) {
        return 'check square outline';
    } else {
        return 'square outline';
    }
}


export const UNSELECT_SKILL = () => ({id:-1, name: 'Skill'})
export const UNSELECT_SKILLLEVEL = () => ({id:-1, name: 'Skill level'})
