/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Header, Message, Segment } from 'semantic-ui-react';
import AvailabilitySummary from '../summary/AvailabilitySummary';
import ContactSummary from '../summary/ContactSummary';
import EditButton from '../../widget/EditButton';
import QualificationSummary from '../summary/QualificationSummary';
import { dismissSubmitError, showForm } from '../../../actions/Worker';
import { FORM_AVAILABILITY, FORM_CONTACT, FORM_QUALIFICATION } from '../../../resources/Worker';
import '../../app.css';
import '../worker.css';


const mapStateToProps = state => {
  return {
      app: state.app,
      worker: state.worker
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
      dismissSubmitError: () => dispatch(dismissSubmitError()),
      navigate: (formId) => dispatch(showForm(formId))
  };
}


class SubmitForm extends Component {
    /*
     * Show a summary of all entered information.
     */
    render() {
        const { navigate, worker } = this.props;
        const {
            isSubmitting,
            submitError
        } = worker;
        // Display error message at bottom if there was a submission error.
        let message = null;
        if (submitError != null) {
            message = (
                <Message
                    icon='inbox'
                    header='There was an error'
                    content={ submitError }
                    negative
                    onDismiss={this.props.dismissSubmitError}
                />
            );
        }
        return (
            <Container fluid>
                <Header as='h3' style={{textAlign: 'left'}}> Personal Information </Header>
                <Segment>
                    <ContactSummary />
                </Segment>
                <EditButton
                    disabled={isSubmitting}
                    onClick={() => (navigate(FORM_CONTACT))}
                />
                <Header as='h3' style={{textAlign: 'left'}}> Qualification & Experience </Header>
                <Segment>
                    <QualificationSummary />
                </Segment>
                <EditButton
                    disabled={isSubmitting}
                    onClick={() => (navigate(FORM_QUALIFICATION))}
                />
                <Header as='h3' style={{textAlign: 'left'}}> Availability </Header>
                <Segment>
                    <AvailabilitySummary />
                </Segment>
                <EditButton
                    disabled={isSubmitting}
                    onClick={() => (navigate(FORM_AVAILABILITY))}
                />
                { message }
            </Container>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitForm);
