/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import { Container, Header, Icon, List } from 'semantic-ui-react';
import './app.css';


/*
 * The main page of the external DS3 app. This page is used to direct the user
 * to one of the two main components of the app.
 */
class MainPage extends Component {
    componentDidMount() {
        document.title = 'Data Science and Software Services (DS3)';
    }
    /*
     * Redirect user to the page for entering new projects.
     */
    goProject = () => {
        window.location.href = '/projects';
    }
    /*
     * Redirect user to page to register a new user.
     */
    goWorker = () => {
        window.location.href = '/workers';
    }
    render() {
        return (
            <Container className='app-content'>
                <div className='app-form'>
                    <Header as='h1' icon><Icon name='home' />Welcome</Header>
                    <div className='main-links'>
                        <List size='massive'>
                            <List.Item as='a' onClick={this.goProject}>
                                I have a project and need DS3 resources
                            </List.Item>
                            <List.Item as='a' onClick={this.goWorker}>
                                I'm a student and want to work for DS3
                            </List.Item>
                        </List>
                    </div>
                </div>
            </Container>
        )
    }
}


export default MainPage;
