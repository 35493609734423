/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/*
 * Upload a list of files. The list of uploads is a pair of file handle ('obj')
 * and file object ('file'). After uploading files usccessfully, we need to add
 * the returned file_id's for the uploaded files to the file handles.
 */
export const uploadFiles = (uploads, data, successHandler, errorHandler) => {
    const url = data.urls.fileUpload();
    const body = new FormData();
    uploads.forEach((obj) => (body.append('file', obj.file)));
    return dispatch => (
        fetch(url, {method: 'POST', body})
            .then(response => {
                if (!response.ok) {
                    response.json().then(json => {
                        dispatch(errorHandler(json.message));
                    });
                } else {
                    response.json().then(json => {
                        // Add file_id's for uploaded files to the file handles
                        // that are part of the uploads list.
                        //
                        // NOTE: Here we rely on the assumption that the order
                        // of files in the result is the same as the order of
                        // files in the upload. In the long-term it would be
                        // safer to have a better mechanism for this.
                        json.files.forEach((obj, i) => (
                            uploads[i].obj['file_id'] = obj.file_id)
                        );
                        dispatch(
                            successHandler(
                                data.project,
                                data.attachments,
                                data.urls
                            )
                        );
                    })
                }
            })
            .catch(error => dispatch(errorHandler(error.message)))
    )
}
