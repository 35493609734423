/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import { connect } from 'react-redux';
import { Button, Message, Icon } from 'semantic-ui-react';
import '../../app.css';


const mapStateToProps = state => {
  return {
      project: state.project
  };
};


class SuccessForm extends Component {
    /*
     * Redirect to the beginning of the workflow, allowing to edit the
     * submitted project.
     */
    goEdit = () => {
        const projectId = this.props.project.projectId;
        window.location.href = '/projects/' + projectId;
    }
    /*
     * Redirect to the beginning of the workflow, allowing to create a new
     * project.
     */
    goNew = () => {
        window.location.href = '/projects';
    }
    render() {
        const message = 'You have successfully submitted the project information.';
        return (
            <div>
                <div className='top-bottom-spaced'>
                    <Message positive icon>
                        <Icon name='check circle' />
                        <Message.Content>{ message }</Message.Content>
                    </Message>
                </div>
                <div className='top-bottom-spaced'>
                    <Button
                        primary
                        onClick={this.goEdit}>
                        Edit project information
                    </Button>
                    <Button
                        positive
                        onClick={this.goNew}>
                        Submit another request
                    </Button>
                </div>
            </div>
        )
    }
}


export default connect(mapStateToProps)(SuccessForm);
