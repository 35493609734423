/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { Icon, Step } from 'semantic-ui-react';
import {
    FORM_DESCRIPTION, FORM_RESEARCHERS, FORM_RESOURCES, FORM_SUBMIT,
    FORM_SUCCESS
} from '../../resources/Project';


/*
 * Show completion status of the project submission. The submission is composed
 * of four different steps.
 */
class ProjectSteps extends Component {
    static propTypes = {
        formId: PropTypes.number.isRequired
    }
    render() {
        const { formId } = this.props;
        return (
            <Step.Group fluid size='small'>
                <Step
                    active={formId === FORM_RESEARCHERS}
                    disabled={formId === FORM_SUCCESS}
                >
                    <Icon name='user secret' />
                    <Step.Content>
                        <Step.Title>Investigators</Step.Title>
                        <Step.Description>Who are the project PI's</Step.Description>
                    </Step.Content>
                </Step>

                <Step
                    active={formId === FORM_DESCRIPTION}
                    disabled={formId < FORM_DESCRIPTION || formId === FORM_SUCCESS}
                >
                    <Icon name='clipboard outline' />
                    <Step.Content>
                        <Step.Title>Description</Step.Title>
                        <Step.Description>Brief project description</Step.Description>
                    </Step.Content>
                </Step>

                <Step
                    active={formId === FORM_RESOURCES}
                    disabled={formId < FORM_RESOURCES || formId === FORM_SUCCESS}
                >
                    <Icon name='group' />
                    <Step.Content>
                        <Step.Title>Resources</Step.Title>
                        <Step.Description>Required Skills</Step.Description>
                    </Step.Content>
                </Step>

                <Step
                    active={formId === FORM_SUBMIT}
                    disabled={formId < FORM_SUBMIT || formId === FORM_SUCCESS}
                >
                    <Icon name='send' />
                    <Step.Content>
                        <Step.Title>Submit</Step.Title>
                        <Step.Description>Verify details and submit</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        )
    }
}

export default ProjectSteps;
