/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, } from 'semantic-ui-react';
import '../app.css';
import './widget.css';


/*
 * Widget that displays a dropdown with a search box. The dropdown will show
 * a controlled vocabulary. The vocabulary is represented by a list of objects
 * that have an .id and .name property.
 */
class SearchableDropdown extends Component {
    static propTypes = {
        enableSearch: PropTypes.bool.isRequired,
        elements: PropTypes.array.isRequired,
        selectedElement: PropTypes.object.isRequired,
        onUpdate: PropTypes.func.isRequired
    }
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintains the search
        // query string.
        this.state = {query: ''};
    }
    /*
     * Handle changes to the query search string.
     */
    handleSearchInput = (event) => {
        this.setState({query: event.target.value});
    }
    /*
     * Render a dropdown showing a controlled vocabulary. Elements are filtered
     * using the search query.
     */
    render() {
        const { enableSearch, elements, selectedElement, onUpdate } = this.props;
        const query = this.state.query.toLowerCase();
        // Filter elements if search is enabled.
        let filteredElements;
        let searchBox = null;
        if (enableSearch === true) {
            filteredElements = elements.filter(
                el => el.name.toLowerCase().includes(query)
            );
            searchBox = (
                <Input
                    id='searchInput'
                    icon='search'
                    iconPosition='left'
                    className='search'
                    onClick={(e) => e.stopPropagation()}
                    onChange={this.handleSearchInput}
                />
            );
        } else{
            filteredElements = elements;
        }
        return (
            <Dropdown
                button
                labeled
                floating
                text={selectedElement.name}
                className='icon large-text'
            >
                <Dropdown.Menu>
                    { searchBox }
                    <Dropdown.Menu scrolling>
                        { filteredElements.map((el, i) => {
                            return <Dropdown.Item
                                key={el.id}
                                text={el.name}
                                value={el.id}
                                onClick={() => onUpdate(el)}
                                className='large-text'
                            />
                        })}
                    </Dropdown.Menu>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default SearchableDropdown;
