/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import {
    SHOW_WORKER_FORM, UPDATE_WORKER_AVAILABILITY, UPDATE_WORKER_CONTACT,
    UPDATE_WORKER_QUALIFICATION, WORKER_FETCH_ERROR, WORKER_FETCH_START,
    WORKER_FETCH_SUCCESS, WORKER_SUBMIT_ERROR, WORKER_SUBMIT_START,
    WORKER_SUBMIT_SUCCESS
} from '../actions/Worker';
import { FORM_CONTACT, FORM_SUCCESS } from '../resources/Worker';


/*
 * The project state maintains information about a new project that is entered
 * by a user via the different entry forms. The project state is divided into
 * three main parts according to the three different input forms:
 *
 * "contact": {
 *   "name": "string",
 *   "email": "string"
 * }
 *
 * "qualification": {
 *   "educations": [{
 *     "school": "string",
 *     "major": "string",
 *     "country": {"id": "string", "name": "string"},
 *     "degreeType": {"id": 0, "name": "string"},
 *     "degreeStatus": {"id": 0, "name": "string"}
 *   }],
 *   "skills": [{
 *     "skill": {"id": 0, "name": "string"},
 *     "skillLevel: {"id": 0, "name": "string"}
 *   }]
 * }
 *
 * "availability": [{
 *    "startDate": Date,
 *    "endDate": Date,
 *    "hours": 0
 * }]
 *
 */
const INITIAL_STATE = {
    availability: [],
    contact: {name: '', email: ''},
    qualification: {educations: [], skills: []},
    fetchError: null,
    formId: FORM_CONTACT,
    isFetching: false,
    isSubmitting: false,
    submitError: null,
    workerId: null
}


/*
 * Reducers for actions that modify information about the worker.
 */
const worker = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_WORKER_CONTACT:
            return {...state, contact: action.payload};
        case UPDATE_WORKER_QUALIFICATION:
            return {...state, qualification: action.payload};
        case UPDATE_WORKER_AVAILABILITY:
            return {...state, availability: action.payload};
        case SHOW_WORKER_FORM:
            return {...state, formId: action.payload};
        case WORKER_FETCH_ERROR:
            return {...state, isFetching: false, fetchError: action.payload};
        case WORKER_FETCH_START:
            return {...state, isFetching: true, fetchError: null};
        case WORKER_FETCH_SUCCESS:
            return {
                ...state,
                isFetching: false,
                fetchError: null,
                workerId: action.payload.workerId,
                contact: action.payload.contact,
                qualification: action.payload.qualification,
                availability: action.payload.availability
            };
        case WORKER_SUBMIT_ERROR:
            return {...state, isSubmitting: false, submitError: action.payload};
        case WORKER_SUBMIT_START:
            return {...state, isSubmitting: true, submitError: null};
        case WORKER_SUBMIT_SUCCESS:
            return {
                ...state,
                formId: FORM_SUCCESS,
                isSubmitting: false,
                submitError: null,
                workerId: action.payload.worker_id,
            };
        default:
            return state;
    }
}


export default worker;
