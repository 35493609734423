/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component} from 'react';
import PropTypes from 'prop-types';
import { Icon, Step } from 'semantic-ui-react';
import {
    FORM_AVAILABILITY, FORM_CONTACT, FORM_QUALIFICATION, FORM_SUBMIT,
    FORM_SUCCESS
} from '../../resources/Worker';


/*
 * Show completion status of the worker registration workflow. The registration
 * workflow is composed of four different steps.
 */
class Steps extends Component {
    static propTypes = {
        formId: PropTypes.number.isRequired
    }
    render() {
        const { formId } = this.props;
        return (
            <Step.Group fluid size='small'>
                <Step
                    active={formId === FORM_CONTACT}
                    disabled={formId === FORM_SUCCESS}
                >
                    <Icon name='user circle' />
                    <Step.Content>
                        <Step.Title>About You</Step.Title>
                        <Step.Description>Personal Information</Step.Description>
                    </Step.Content>
                </Step>

                <Step
                    active={formId === FORM_QUALIFICATION}
                    disabled={formId < FORM_QUALIFICATION || formId === FORM_SUCCESS}
                >
                    <Icon name='graduation cap' />
                    <Step.Content>
                        <Step.Title>Education</Step.Title>
                        <Step.Description>Qualification & Experience</Step.Description>
                    </Step.Content>
                </Step>

                <Step
                    active={formId === FORM_AVAILABILITY}
                    disabled={formId < FORM_AVAILABILITY || formId === FORM_SUCCESS}
                >
                    <Icon name='calendar alternate outline' />
                    <Step.Content>
                        <Step.Title>Availability</Step.Title>
                        <Step.Description>Availability Information</Step.Description>
                    </Step.Content>
                </Step>

                <Step
                    active={formId === FORM_SUBMIT}
                    disabled={formId < FORM_SUBMIT || formId === FORM_SUCCESS}
                >
                    <Icon name='send' />
                    <Step.Content>
                        <Step.Title>Submit</Step.Title>
                        <Step.Description>Verify details and submit</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        )
    }
}

export default Steps;
