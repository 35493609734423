/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { parseDate } from './Util';


// Project workflow main  forms
export const FORM_COUNT = 5;

export const FORM_RESEARCHERS = 0;
export const FORM_DESCRIPTION = 1;
export const FORM_RESOURCES = 2;
export const FORM_SUBMIT = 3;
export const FORM_SUCCESS = 4;


// Empty project funding selection
export const UNSELECTED_FUNDING = () => ({id:-1, name: 'Choose One'})


/*
 * Convert project handle returned by the API into the representation that is
 * expected by UI components that access the project in the application state.
 */
export const Project = (data, masterdata) => {
    const description = {
        title: data.title,
        description: data.description,
        startDate: parseDate(data.expected_time.start_date),
        endDate: parseDate(data.expected_time.end_date),
        funding: masterdata.projectFundingStatus.find((f) => (
            f.id === data.funding_status_id
        )),
        attachments: data.attachments.files.concat(data.attachments.links)
    };
    return {
        projectId: data.project_id,
        description,
        researchers: data.researchers.map((r) => (Researcher(r, masterdata))),
        resources: data.resources.map((r) => (Resource(r, masterdata)))
    };
}


const Researcher = (data, masterdata) => {
    return {
        researcher_id: data.researcher_id,
        name: data.full_name,
        email: data.email,
        affiliations: data.affiliations.map((a) => ({
            department: masterdata.affiliations.find((d) => (
                d.id === a.affiliation_id
            )),
            role: masterdata.affiliationStatus.find((r) => (
                r.id === a.status_id
            ))
        }))
    }
}

const Resource = (data, masterdata) => {
    return {
        resource_rqmt_id: data.resource_rqmt_id,
        startDate: parseDate(data.expected_time.start_date),
        endDate: parseDate(data.expected_time.end_date),
        hours: data.weekly_hours.toString(),
        skills: data.skills.map((obj) => ({
            skill: masterdata.skills.find((s) => (s.id === obj.skill_id)),
            skillLevel: masterdata.skillLevels.find((s) => (
                s.id === obj.skill_level_id
            )),
            mandatory: obj.mandatory
        }))
    }
}
