/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, List } from 'semantic-ui-react';
import ListItem from '../../widget/ListItem';
import ResourceForm from '../subform/ResourceForm';
import { updateProjectResources } from '../../../actions/Project';
import { UNSELECT_SKILL, UNSELECT_SKILLLEVEL } from '../../../resources/Skill';
import { del, replace, timePeriod, Today } from '../../../resources/Util'
import '../project.css';


const mapStateToProps = state => {
  return {
      app: state.app,
      project: state.project
  };
};

function mapDispatchToProps(dispatch) {
  return {
      updateState: (data) => dispatch(updateProjectResources(data))
  };
}


const CLEAN_STATE = () => ({
    startDate: Today(),
    endDate: Today(),
    hours: '',
    mandatory: false,
    skills: [],
    selectedSkill: UNSELECT_SKILL(),
    selectedSkillLevel: UNSELECT_SKILLLEVEL(),
    selectedResource: -1,
    resources: []
})


const concatSkills = (skills) => {
    let text = '';
    for (let i = 0; i < skills.length; i++) {
        const skill = skills[i].skill;
        if (skill.id !== -1) {
            if (text !== '') {
                text += ', ';
            }
            text += skill.name;
        }
    }
    if (text !== '') {
        return text;
    } else {
        return 'No Skills Selected';
    }
}


class ResourceRequestForm extends Component {
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintains the list of
        // requested resources for the project.
        this.state = CLEAN_STATE();
    }
    /*
     * Initialize the local form state from the global project state when the
     * component mounts.
     */
    componentDidMount() {
        // Get list of resources. If the list is not empty we edit the last
        // resource in the list by default.
        const resources = this.props.project.resources;

        if (resources.length > 0) {
            const selectedResource = resources.length - 1;
            const lastResource = resources[selectedResource];
            this.setState({
                startDate: lastResource.startDate,
                endDate: lastResource.endDate,
                hours: lastResource.hours,
                mandatory: false,
                skills: lastResource.skills,
                selectedSkill: UNSELECT_SKILL(),
                selectedSkillLevel: UNSELECT_SKILLLEVEL(),
                selectedResource: selectedResource,
                resources: resources
            })
        }
    }
    /*
     * Update the form state in the global application state.
     */
    componentWillUnmount() {
        this.props.updateState(this.updateResourceList());
    }
    /*
     * Add a new resource to the list.
     */
    addResource = () => {
        const newState = CLEAN_STATE()
        newState.resources = this.updateResourceList()
        this.setState(newState);
    }
    /*
     * Add the selected skill to the list of skills for the current resource.
     */
    addSelectedSkill = () => {
        this.setState({
            mandatory: false,
            skills: this.state.skills.concat([{
                skill: this.state.selectedSkill,
                skillLevel: this.state.selectedSkillLevel,
                mandatory: this.state.mandatory
            }]),
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL()
        });
    }
    /*
    * Clear selections in the skills form.
    */
    clearSkillForm = () => {
        this.setState({
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL(),
            mandatory: false
        })
    }
    /*
     * Delete the resource that is currently selected.
     */
    deleteResource = () => {
        const { resources, selectedResource } = this.state;
        const updResources = del(resources, selectedResource);
        let resource = null;
        let newSelection = -1;
        if (updResources.length > 0) {
            newSelection = selectedResource;
            if (newSelection >= updResources.length) {
                newSelection--;
            } else if (newSelection < 0) {
                newSelection = 0;
            }
            resource = updResources[newSelection];
        } else {
            resource = CLEAN_STATE();
        }
        this.setState({
            startDate: resource.startDate,
            endDate: resource.endDate,
            hours: resource.hours,
            mandatory: false,
            skills: resource.skills,
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL(),
            selectedResource: newSelection,
            resources: updResources
        });
    }
    /*
     * Delete the given skill from the list of skills for the current resource.
     */
    deleteSelectedSkill = (index) => {
        this.setState({skills: del(this.state.skills, index)});
    }
    /*
     * Edit the resource at the given position in the resource array.
     */
    editResource = (index) => {
        const resource = this.state.resources[index];
        this.setState({
            startDate: resource.startDate,
            endDate: resource.endDate,
            hours: resource.hours,
            skills: resource.skills,
            selectedSkill: UNSELECT_SKILL(),
            selectedSkillLevel: UNSELECT_SKILLLEVEL(),
            mandatory: false,
            selectedResource: index,
            resources: this.updateResourceList()
        });
    }
    /*
     * Render the input form for resource information. For each resource we
     * collect the expected start and end date, and the list of required or
     * desired skills.
     */
    render() {
        const {
            startDate,
            endDate,
            hours,
            mandatory,
            skills,
            selectedSkill,
            selectedSkillLevel,
            selectedResource,
            resources
        } = this.state;
        const skillTaxonomy = this.props.app.masterdata.skills;
        const skillLevels = this.props.app.masterdata.skillLevels;
        const masterdata = {skillTaxonomy, skillLevels};
        // Show the delete resource option in the header menu if there is more
        // than one resource in the list.
        let callbackDeleteResource = null;
        if (!(((selectedResource === 0) && (resources.length === 1)) || (resources.length === 0))) {
            callbackDeleteResource = this.deleteResource;
        }
        let newResourceForm = null;
        if (selectedResource === -1) {
            newResourceForm = (
                <List.Item key={-1}>
                <ResourceForm
                    startDate={startDate}
                    endDate={endDate}
                    hours={hours}
                    mandatory={mandatory}
                    skills={skills}
                    selectedSkill={selectedSkill}
                    selectedSkillLevel={selectedSkillLevel}
                    masterdata={masterdata}
                    onAddSkill={this.addSelectedSkill}
                    onClearSkill={this.clearSkillForm}
                    onDeleteResource={callbackDeleteResource}
                    onDeleteSkill={this.deleteSelectedSkill}
                    onUpdate={this.setStateProperty}
                />
                </List.Item>
            );
        }
        return (
            <div>
                <List divided relaxed size='big'>
                    {resources.map((obj, i) => {
                        if (i === selectedResource) {
                            return (
                                <List.Item key={i}>
                                    <ResourceForm
                                        startDate={startDate}
                                        endDate={endDate}
                                        hours={hours}
                                        mandatory={mandatory}
                                        skills={skills}
                                        selectedSkill={selectedSkill}
                                        selectedSkillLevel={selectedSkillLevel}
                                        masterdata={masterdata}
                                        onAddSkill={this.addSelectedSkill}
                                        onDeleteResource={callbackDeleteResource}
                                        onClearSkill={this.clearSkillForm}
                                        onDeleteSkill={this.deleteSelectedSkill}
                                        onUpdate={this.setStateProperty}
                                    />
                                </List.Item>
                            );
                        } else {
                            return (
                                <ListItem
                                    key={i}
                                    index={i}
                                    icon='user'
                                    header={concatSkills(obj.skills)}
                                    name={timePeriod(startDate, endDate)}
                                    onClick={this.editResource}
                                />
                            );
                        }
                    })}
                    { newResourceForm }
                </List>
                <div className='form-action-buttons'>
                    <Button
                        icon
                        labelPosition='left'
                        primary
                        size='small'
                        onClick={this.addResource}
                    >
                        <Icon name='user' />
                        Add Resource
                    </Button>
                </div>
            </div>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    /*
     * Create a resource object from the current form state.
     */
    updateResourceList = () => {
        // Construct an object (from the component state) that is a list of
        // resource objects with the following format:
        //
        // - startDate: "string"
        // - endDate: "string"
        // - horus: "integer"
        // - skills: [{
        //   - skill: {"id": "integer", "name": "string"}
        //   - skillLevel: {"id": "integer", "name": "string"}
        //   - mandatory: "boolean"
        //
        // }]
        const {
            startDate,
            endDate,
            hours,
            mandatory,
            skills,
            selectedSkill,
            selectedSkillLevel
        } = this.state;
        // Add the current value in the skill form if at least one of the
        // dropdowns has a selected value.
        const updSkills = [...skills];
        if ((selectedSkill.id !== -1) || (selectedSkillLevel.id !== -1)) {
            updSkills.push({
                skill: selectedSkill,
                skillLevel: selectedSkillLevel,
                mandatory
            });
        }
        const resource = {
            startDate: startDate,
            endDate: endDate,
            hours: hours,
            skills: updSkills
        }
        // Depending on the value of the currently selected resource the
        // resource that is being edited is either appended to the list or
        // the item in the list is replaced.
        const { resources, selectedResource } = this.state;
        if (selectedResource === -1) {
            return [...this.state.resources, resource];
        } else {
            return replace(resources, selectedResource, resource);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceRequestForm);
