/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import ContextMenu from '../../widget/ContextMenu';
import SearchableDropdown from '../../widget/SearchableDropdown';
import '../../app.css';
import '../worker.css';


class EducationForm extends Component {
    static propTypes = {
        masterdata: PropTypes.object.isRequired,
        major: PropTypes.string.isRequired,
        onDelete: PropTypes.func,
        onUpdate: PropTypes.func.isRequired,
        school: PropTypes.string.isRequired,
        selectedCountry: PropTypes.object.isRequired,
        selectedDegreeStatus: PropTypes.object.isRequired,
        selectedDegreeType: PropTypes.object.isRequired
    }
    /*
     * Event handler for changes in the country dropdown.
     */
    handleCountryUpdate = (country) => {
        this.props.onUpdate('selectedCountry', country);
    }
    /*
     * Event handler for changes in the degree status dropdown.
     */
    handleDegreeStatusUpdate = (status) => {
        this.props.onUpdate('selectedDegreeStatus', status);
    }
    /*
     * Event handler for changes in the country dropdown.
     */
    handleDegreeTypeUpdate = (degree) => {
        this.props.onUpdate('selectedDegreeType', degree);
    }
    /*
     * Event handler for changes in the major text files.
     */
    handleMajorUpdate = (e) => {
        this.props.onUpdate('major', e.target.value);
    }
    /*
     * Event handler for changes in the school name text files.
     */
    handleSchoolUpdate = (e) => {
        this.props.onUpdate('school', e.target.value);
    }
    /*
     * Render the input form for worker education information. The
     * controlled vocabularies for country code, degree type, degree status,
     * skill name, and skill proficiency are included in the masterdata
     * that is part of the application state (this.props.app.masterdata).
     */
    render() {
        const {
            major,
            masterdata,
            onDelete,
            school,
            selectedCountry,
            selectedDegreeStatus,
            selectedDegreeType,
        } = this.props;
        const { countries, degreeTypes, degreeStatus } = masterdata;
        // The header menu is only displayed if the education deletion handler
        // is given.
        let headerMenu = null;
        if (onDelete != null) {
            headerMenu = (
                <ContextMenu
                    text='Delete Degree'
                    onDelete={onDelete}
                />
            );
        }
        return (
            <Form size='large'>
                { headerMenu }
                <Form.Group>
                    <Form.Field width={10}>
                        <Form.Input
                            label="School Name"
                            onChange={this.handleSchoolUpdate}
                            placeholder="School Name"
                            value={school}
                        />
                    </Form.Field>
                    <Form.Field width={6}>
                        <label>Country</label>
                        <SearchableDropdown
                            enableSearch
                            elements={countries}
                            selectedElement={selectedCountry}
                            onUpdate={this.handleCountryUpdate}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group className="long content">
                    <Form.Input
                        label="Major"
                        onChange={this.handleMajorUpdate}
                        placeholder="Major"
                        value={major}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Degree Type</label>
                        <SearchableDropdown
                            enableSearch={false}
                            elements={degreeTypes}
                            selectedElement={selectedDegreeType}
                            onUpdate={this.handleDegreeTypeUpdate}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Degree Status</label>
                        <SearchableDropdown
                            enableSearch={false}
                            elements={degreeStatus}
                            selectedElement={selectedDegreeStatus}
                            onUpdate={this.handleDegreeStatusUpdate}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        );
    }
}

export default EducationForm;
