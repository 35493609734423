
/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataSummary from '../../widget/DataSummary';
import { FormatedText, SkillListing, tableCss } from '../../widget/SummaryHelpers';
import { dateToStr } from '../../../resources/Util';


const mapStateToProps = state => {
    return {
        project: state.project
    };
};


/*
 * Listing of requested resources for a project.
 */
class ResourceListing extends Component {
    render() {
        const resources = this.props.project.resources;
        return (
            <div>
            { resources.map((obj, i) => {
                const { startDate, endDate, hours, skills } = obj;
                const values = [];
                values.push({
                    label: 'Expected start date',
                    value: dateToStr(startDate)
                });
                values.push({
                    label: 'Expected end date',
                    value: dateToStr(endDate)
                });
                values.push({
                    label: 'Hours',
                    value: <FormatedText text={hours} message='Hours is missing' />
                });
                values.push({
                    label: 'Skills',
                    value: <SkillListing skills={skills} />
                });
                return (
                    <DataSummary
                        key={i}
                        className={tableCss(i, resources)}
                        elements={values}
                    />
            );})}
            </div>
        );
    }
}

export default connect(mapStateToProps)(ResourceListing);
