
/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataSummary from '../../widget/DataSummary';
import { FormatedObject, FormatedText, tableCss } from '../../widget/SummaryHelpers';


const mapStateToProps = state => {
    return {
        project: state.project
    };
};


/*
 * Listing of principle investigators for a project.
 */
class ResearcherListing extends Component {
    render() {
        const researchers = this.props.project.researchers;
        return (
            <div>
            { researchers.map((obj, i) => {
                const { name, email, affiliations } = obj;
                let affiliationList;
                if (affiliations.length > 0) {
                    affiliationList = affiliations.map((obj, i) => {
                        const { department, role } = obj;
                        return (
                            <div key={i}>
                                <FormatedObject
                                    object={department}
                                    message='Department not selected'
                                />
                                {' '}(<FormatedObject object={role} message='Role not selected' />)
                            </div>
                        );
                    });
                } else {
                    affiliationList = 'No affiliation';
                }
                const values = [];
                values.push({
                    label: 'Full Name',
                    value: <FormatedText text={name} message='Full name is missing' />}
                );
                values.push({
                    label: 'Email',
                    value: <FormatedText text={email} message='Email is missing' />}
                );
                values.push({label: 'Affiliation', value: affiliationList});
                return (
                    <DataSummary
                        key={i}
                        className={tableCss(i, researchers)}
                        elements={values}
                    />
                );
            })}
            </div>
        );
    }
}

export default connect(mapStateToProps)(ResearcherListing);
