/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Container, Grid, Header, Icon, Loader, Message } from 'semantic-ui-react'
import Navigation from '../Navigation';
import ProjectDescriptionForm from './form/ProjectDescriptionForm';
import PrincipleInvestigatorForm from './form/PrincipleInvestigatorForm';
import ResourceRequestForm from './form/ResourceRequestForm';
import ProjectSteps from './ProjectSteps';
import SubmitForm from './form/SubmitForm';
import SuccessForm from './form/SuccessForm';
import { fetchProject, showForm, submitProject } from '../../actions/Project';
import {
    FORM_COUNT, FORM_RESEARCHERS, FORM_DESCRIPTION, FORM_RESOURCES,
    FORM_SUBMIT, FORM_SUCCESS
} from '../../resources/Project';
import { isEmpty } from '../../resources/Util';
import '../app.css';


function mapStateToProps(state) {
  return {
      app: state.app,
      project: state.project
  };
};


function mapDispatchToProps(dispatch) {
  return {
      fetchProject: (url, masterdata) => dispatch(fetchProject(url, masterdata)),
      showForm: (formId) => dispatch(showForm(formId)),
      submitProject: (project, urls) => dispatch(submitProject(project, urls))
  };
}


class ProjectWorkflow extends Component {
    componentDidMount() {
        // Fetch project handle if the route contains a project id
        const projectId = this.props.match.params.project_id;
        if (projectId != null) {
            const { masterdata, urls } = this.props.app;
            this.props.fetchProject(urls.projectsFetch(projectId), masterdata);
        }
    }
    handleSubmit = () => {
        const { app, project, submitProject } = this.props;
        submitProject(project, app.urls);
    }
    render() {
        const {
            isFetching,
            isSubmitting,
            fetchError,
            formId
        } = this.props.project;
        let content = null;
        if (fetchError != null) {
            // There was an error while fetching the project handle.
            content = (
                <div className='app-notification'>
                    <Message icon negative>
                        <Icon name='warning' />
                        <Message.Content>
                            <Message.Header>
                                There was an error fetching the project information
                            </Message.Header>
                            { fetchError }
                        </Message.Content>
                    </Message>
                </div>
            );
        } else if (isFetching === true) {
            // Show a spinner to indicate that the API service descriptor
            // is currently being fetched (and that the application is not
            // ready).
            content = (
                <div className='app-notification'>
                    <Loader inline='centered' active>
                        Loading Project ...
                    </Loader>
                </div>
            );
        } else {
            // Show input form for the current workflow step.
            let form = null;
            let header = null;
            if (formId === FORM_RESEARCHERS) {
                form = <PrincipleInvestigatorForm />;
                header = 'Principle Investigators'
            } else if (formId === FORM_DESCRIPTION) {
                form = <ProjectDescriptionForm  />;
                header = 'Project Description'
            } else if (formId === FORM_RESOURCES) {
                form = <ResourceRequestForm />;
                header = 'Resource Requirements'
            } else if (formId === FORM_SUBMIT) {
                form = <SubmitForm />;
                header = 'Summary'
            } else if (formId === FORM_SUCCESS) {
                form = <SuccessForm />;
                header = 'Thank you!';
            }
            content = (
                <Grid columns={3} container>
                    <Grid.Row>
                        <Grid.Column width={1} />
                        <Grid.Column width={14}>
                        <Header
                            as='h1'
                            textAlign='left'
                            style={{'marginTop': '10px', 'marginBottom': '20px'}}
                        >
                            { header }
                        </Header>
                        { form }
                        </Grid.Column>
                        <Grid.Column width={1} />
                    </Grid.Row>
                </Grid>
            );
        }
        let navigation = null;
        if ((formId !== FORM_SUCCESS) && (fetchError == null) && (isFetching === false)) {
            let isValidInput = false;
            if (formId === FORM_SUBMIT) {
                isValidInput = this.validateInput();
            }
            navigation = (
                <Navigation
                    formId={formId}
                    formCount={FORM_COUNT}
                    isSubmitting={isSubmitting}
                    isValidInput={isValidInput}
                    onNavigate={this.props.showForm}
                    onSubmit={this.handleSubmit}
                />
            );
        }
        return (
            <Container className='app-content'>
                <ProjectSteps formId={formId} />
                <div className='app-form'>
                    { content }
                    { navigation }
                </div>
            </Container>
        );
    }
    /*
     * Validate the input values. Returns false if the current input values are
     * not valid.
     */
    validateInput = () => {
        const { researchers, description, resources } = this.props.project;
        // Each researcher has to have at least a name and email.
        if (researchers.length === 0) {
            return false;
        }
        for (let i = 0; i < researchers.length; i++) {
            const { name, email, affiliations } = researchers[i];
            if ((isEmpty(name)) || (isEmpty(email))) {
                return false;
            }
            for (let j = 0; j < affiliations.length; j++) {
                const { department, role } = affiliations[j];
                if (department == null) {
                    return false;
                }
                if (department.id === -1) {
                    return false;
                }
                if (role == null) {
                    return false;
                }
                if (role.id === -1) {
                    return false;
                }
            }
        }
        // For the project description the title, description, startDate,
        // endDate, and funding all have to be given.
        if (isEmpty(description.title)) {
            return false;
        }
        if (isEmpty(description.description)) {
            return false;
        }
        if (description.startDate == null) {
            return false;
        }
        if (description.endDate == null) {
            return false;
        }
        if (description.funding == null) {
            return false;
        }
        if (description.funding.id === -1) {
            return false;
        }
        // For a resource startDate, endDate, hours and skills have to be
        // selected. Selected skills have to have a skill level.
        if (resources.length === 0) {
            return false;
        }
        for (let i = 0; i < resources.length; i++) {
            const { startDate, endDate, hours, skills } = resources[i];
            if (startDate == null) {
                return false;
            }
            if (endDate == null) {
                return false;
            }
            if (isEmpty(hours)) {
                return false;
            }
            if (skills.length === 0) {
                return false;
            }
            for (let j = 0; j < skills.length; j++) {
                const { skill, skillLevel } = skills[j];
                if (skill == null) {
                    return false;
                }
                if (skill.id === -1) {
                    return false;
                }
                if (skillLevel == null) {
                    return false;
                }
                if (skillLevel.id === -1) {
                    return false;
                }
            }
        }
        return true;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectWorkflow));
