/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Form, Input } from 'semantic-ui-react';
import { updateWorkerContact } from "../../../actions/Worker";
import '../worker.css';


const mapStateToProps = state => {
  return {
      app: state.app,
      worker: state.worker
  };
};

function mapDispatchToProps(dispatch) {
  return {
      updateState: (data) => dispatch(updateWorkerContact(data))
 };
}

class ContactInformationForm extends Component {
    constructor(props) {
        super(props);
        // Set the component of the internal state that maintains the worker's
        // personal information. Add additional elements here if required
        // by the form.
        this.state = {
          name: '',
          email: '',
        };
    }
    /*
     * Initialize the local form state from the global worker state when the
     * component mounts.
     */
    componentDidMount() {
        const contact = this.props.worker.contact;
        if (contact != null) {
            this.setState({
                name: contact.name,
                email: contact.email
            })
        }
    }
    /*
     * Update the form state in the global application state.
     */
    componentWillUnmount() {
        // Construct an object (from the component state) that is a dictionary
        //  object with the following format:
        //
        // - name: "string"
        // - email: "string"
        //
        const contact = {
            'name': this.state.name,
            'email': this.state.email
        };

        this.props.updateState(contact);
    }
    /*
     * Render the input form for worker information. For all workers
     * we collect the full name and email.
     */
    render() {
        const { email, name } = this.state;
        return (
            <Container fluid>
                <Form size='large'>
                    <Form.Field
                        control={Input}
                        label='Full Name'
                        onChange={(e) => this.setStateProperty('name', e.target.value)}
                        placeholder="Full Name"
                        value={name}
                    />
                    <Form.Field
                        control={Input}
                        label='Email'
                        onChange={(e) => this.setStateProperty('email', e.target.value)}
                        placeholder='Email'
                        value={email}
                    />
                </Form>
            </Container>
        );
    }
    /*
     * Update single property in the component state.
     */
    setStateProperty = (name, value) => {
        this.setState({
            [name]: value
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformationForm);
