/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { combineReducers } from 'redux';
import app from './App';
import project from './Project';
import worker from './Worker';


/*
 * Reducer for the global application state. The state is composed of three
 * main parts:
 * (1) the app that maintains information about API routes and contains
 * the controlled vocabularies for different project properties,
 * (2) the project that maintains all information entered by the user about a
 * new project proposal, and
 * (3) the worker that is being registered.
 */
const rootReducer = combineReducers({
    app,
    project,
    worker
})

export default rootReducer;
