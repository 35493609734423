
/* This file is part of the DS3 Workbench.
 * Copyright (C) 2019-2020  New York University
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DataSummary from '../../widget/DataSummary';
import { FormatedText } from '../../widget/SummaryHelpers';


const mapStateToProps = state => {
    return {
        worker: state.worker
    };
};

/*
 * Show summary of workers' contact information.
 */
class ContactSummary extends Component {
    render() {
        const { name, email } = this.props.worker.contact;
        // Create list of (label, value)-pairs for displaying the entered
        // information.
        const elements = [];
        elements.push({
            label: 'Full name',
            value: <FormatedText text={name} message='Full name is missing' />}
        );
        elements.push({
            label: 'Email',
            value: <FormatedText text={email} message='Email is missing' />}
        );
        return (<DataSummary className='last-prop-table' elements={elements} />);
    }
}

export default connect(mapStateToProps)(ContactSummary);
